import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            HOME: "HOME",
            title1: "The agency estimates that the number of people on Black Myth's wishlist exceeds 4 million! 70% are from China",
            des1: "As the release date of August 20 approaches, Black Myth: Wukong has been attracting much attention from players at home and abroad, and has always been ranked first on the Steam wishlist collection rankings. However, the Steam backend does not show the specific number of collections. ",
            title2: 'box responds to the delay of "Black Myth: Wukong" which sparked heated discussion among players: Did Sony buy the exclusive rights?',
            des2: "Earlier this month, Game Science announced that the release of Black Myth: Wukong on the Xbox Series X|S platform would be postponed. As soon as the news came out, it attracted widespread attention and discussion. Many people speculated that the reason for the delay was due to the insufficient performance of the Xbox Series S console, which prevented the development team from completing the optimization work within the scheduled time.",
            title3: 'Suspected fan-made MSI graphics card for "Black Myth: Wukong" exposed, with handsome Monkey King printed on the back panel',
            des3: 'Recently, a graphics card for "Black Myth: Wukong" was discovered on the Internet. The pictures of these customized graphics cards were posted by user hongxing2020 on X/Twitter.',
            title4: "Microsoft responds to Black Myth's delay IGN: hints that Sony and Youke have an exclusive agreement",
            des4: 'Earlier this month, Game Science confirmed that the XSX|S version of "Black Myth: Wukong" will be delayed due to optimization issues on the platform. According to IGN, Xbox officials responded to this matter today.',
            title5: "Gamerant tries out Black Myth: Wukong: It's a real bargain and leaves you wanting more",
            des5: 'Recently, Black Myth: Wukong launched an offline trial experience event overseas, and foreign media Gamerant shared its review experience. After a few hours of trial experience, the author said that Black Myth "is the real deal" and is as good as many previous demonstrations, and is a game worth paying attention to.',
            backToHome: "Back to Home",
            new1des1:
                "As the release date of August 20 approaches, Black Myth: Wukong has been attracting much attention from players at home and abroad, and has always been ranked first on the Steam wishlist collection rankings. However, the Steam backend does not show the specific number of collections.",
            new1des2: ` According to the latest report released by market research website Game World Observer, after the latest trailer of "Black Myth: Wukong" was released at the Summer Game Festival, the game's Steam wishlist collection increased by about 854,000, exceeding any other game exhibited during the game show from June 7 to 13. The report estimates that the total number of wishlist collections of "Black Myth: Wukong" has exceeded 4 million, of which 60%-70% of users are from China.`,
            new1des3:
                "For other games, Civilization 7 added 593,000 collections, Seven Days added 281,000 collections, Dragon Ball Lightning Zero added 182,000 collections, Doom: Dark Age added 138,000 collections, and Monster Hunter Wilds added 129,000 collections.",
            new1des4: ` The research website estimates Steam wishlists by adding a certain multiplier to the number of followers, usually 7 to 20 times the number of fans (the median is 12 times). So it is not accurate and is for reference only.`,
            new2des1: ` Earlier this month, Game Science announced that the release of Black Myth: Wukong on the Xbox Series X|S platform would be postponed. As soon as the news came out, it attracted widespread attention and discussion. Many people speculated that the reason for the delay was due to the insufficient performance of the Xbox Series S console, which prevented the development team from completing the optimization work within the scheduled time.`,
            new2des2: ` In the face of all kinds of speculation from the outside world, Xbox officials recently issued a response to try to clarify the issue.`,
            new2des3: `In the statement, Xbox said: "We are very happy that "Black Myth: Wukong" will be launched on XSX|S, and we are working with Game Science to bring this highly anticipated game to our platform. We cannot comment on the deals our partners have reached with other platform holders. But we are still focused on making Xbox the best platform for gamers, and excellent games are at the core of it."`,
            new2des4: ` Although the statement seems calm, careful players have found hints in it. Some players believe that the "deals with other platform holders" mentioned by Xbox in the statement are actually hinting that Sony may have reached some kind of exclusive agreement with Game Science. This view quickly sparked heated discussions on social media. One user commented: "So, @JezCorden is right, Sony is indeed signing exclusive agreements like crazy." Another user said: "PlayStation is paying for limited-time exclusivity again. If Xbox did this, they would definitely be scolded badly."`,
            new2des5: ` At the same time, some players think that Xbox's statement is shirking responsibility. One player commented: "This is a bit ironic. And it implies that this is a deal, not because of the console performance requirements of the Xbox Series S." Another user was more blunt: "Sony will continue to take this unfair competition approach to maintain its leading position in the console market because they know how to compete. Microsoft/Xbox will continue to give away games for free, and this platform will become less and less attractive to players and studios, and sales will continue to decline.""`,
            new2des6: `  Some players expressed dissatisfaction with Xbox's response, believing that it was trying to blame its own problems on competitors. One user sarcastically said: "Look at Microsoft trying to blame Sony for the problem, hahahaha."`,
            new2des7: `Black Myth: Wukong will be officially released on August 20th and will be available on PC and PS5 platforms. The specific release date of the XSX|S version of Black Myth: Wukong is still unclear. I hope that as time goes by, Game Science and Xbox will be able to bring more positive news to players.`,
            new3des1: `Recently, a graphics card for "Black Myth: Wukong" was discovered on the Internet. The pictures of these customized graphics cards were posted by user hongxing2020 on X/Twitter.`,
            new3des2: ` Unlike other MSI collaboration products, this graphics card does not have too many personalized models, only the picture of the protagonist "Tianmingren" is printed on the back plate. This may be more like a personalized fan work than an official product.`,
            new3des3: ` When the technology website Videocardz asked the original publisher whether it was "custom or a new model", the user hongxing2020 replied "Maybe...", adding more speculation to this.`,
            new3des4: `"Black Myth: Wukong" is expected to be released on August 20, 2024, so we expect to have definite news in the next few weeks.`,
            new4des1: `Earlier this month, Game Science confirmed that the XSX|S version of "Black Myth: Wukong" will be delayed due to optimization issues on the platform. According to IGN, Xbox officials responded to this matter today.`,
            new4des2: ` "We are excited to have Black Myth: Wukong on XSX|S, and we are working with Game Science to bring the game to our platform. We cannot comment on deals our partners have made with other platform holders, but we remain focused on making Xbox the best platform for gamers, and great games are at the core of that."`,
            new4des3: ` In addition, IGN also mentioned rumors that Game Science may have reached an exclusive agreement with Sony, and believes that Microsoft may have hinted at this in this statement.`,
            new5des1: `Recently, Black Myth: Wukong launched an offline trial experience event overseas, and foreign media Gamerant shared its review experience. After a few hours of trial experience, the author said that Black Myth "is the real deal" and is as good as many previous demonstrations, and is a game worth paying attention to.`,
            new5des2: `The article points out that Black Myth is not a full-fledged Souls game. The combat in this game is fast and intense, without traditional parrying and blocking, and dodging is particularly important in the game. In addition, the world of Souls games is more open, while the first few hours of Black Myth seem to be quite linear. In terms of character growth, Black Myth is improved through standard skill trees and acquired abilities, rather than by spending points to enhance attributes such as strength and endurance.`,
            new5des3: ` Combos can cause huge damage to enemies, and mixing in charged heavy attacks allows players to show off their skills in different ways. Players need to take risks to deal damage, but also dodge when necessary. The process of making choices is a fascinating experience. And with the addition of spells and transformations, the game will become more interesting.`,
            new5des4: ` The author points out that Game Science has successfully adapted "Journey to the West" and created some unique and memorable enemies. The author encountered at least 5 bosses in 3 hours, some of whom like to attack, some of whom are defensive, but most of them are both. Defeating the boss will gain some unique transformation abilities, which are crucial to the evolution of Sun Wukong.`,
            new5des5: `Finally, the author said that the best reward for a trial content is "it can make people want more", and "Black Myth" has achieved this. He wrote: "I want to see more memorable worlds, improve my skills, challenge more bosses, and experience the evolution of combat. "Black Myth" does have impressive combat and beautiful graphics. It is the real deal, not a gimmick. This is one of the games I am most looking forward to in the second half of 2024."`,
            source: "Source",
            edit: "Edit",
            EXCHANGE: "EXCHANGE",
            OVERVIEW: "OVERVIEW",
            o1des1: `After Journey to the West, demons and monsters fought each other. The previous generation of "Heavenly Dao" and the demon coalition led by Wukong successfully defeated the Buddhist and Taoist sects and became the new ruling class of the Three Realms. The Dharma Ending Age came, Tathagata Nirvana, Maitreya reincarnated, and Taoism went into seclusion. But gradually Wukong found something wrong, he seemed to be used, the world did not get better as he imagined, but got worse and worse, so he was filled with self-blame and guilt,`,
            o1des2: `and began to look for a new way to save the world.`,
            TOKENPMIC: "TOKENPMIC",
            t1des1: `Incentives`,
            t1des2: ` Cex List`,
            t1des3: ` Fair Launch`,
            PROGRAM: "INCENTIVE PROGRAM",
            p1des1: `Through finishing the game of " Black Myth: Wukong", players help to`,
            p1des2: ` promote the achievement system.`,
            p1des3: `They can trade the badges of the achievement system for airdrops.`,
            p1des4: ` Tiered airdrop awards are release based on the degree of achievement difficulty.`,
            p1des5: `If all achievements are finished, extra incentives will be awarded .`,
            returnInfo: `< Return to Information Home Page`,
            gameInfo: `Game Information`,
            TOKENOMICS: `TOKENOMICS`,
            e1des: ` Qualification of Black Myth: Wukong`,
            enter: `ENTER`,
            congratulations: `Congratulations! You have successfully redeemed`,
            s1des1: `Go to the Steam platform to activate your game. Please do not send the activation code to scammers (we are not responsible for the losses caused by your erroneous operation)`,
            ok: "OK",
            userPrivacy: `User Agreement and Privacy policies`,
            userPdes1: ` Welcome to the support and attention for the game Black Myth: Wukong! $wukong aims to promote and publicize this game through community efforts and reward players' in-game activities. Before you proceed, please read the following statement carefully:`,
            userPdes2: ` Community Token Nature: This token is issued spontaneously by a community of enthusiasts of the game Black Myth: Wukong, not by the official game developers. Its purpose is to promote and publicize the game through community efforts and reward players' in-game activities, rather than having any actual monetary value.`,
            userPdes3: ` Unofficial Token: This token has no affiliation with the developers or official team of Black Myth: Wukong. The official game developers are not responsible for the issuance, use, or any activities related to this token.`,
            userPdes4: ` Reward Nature: This token is intended to reward players' in-game activities. Players who hold or use this token may receive special rewards or honors within the community, but no form of return or profit is promised.`,
            userPdes5: `   Non-Investment Tool: This token is not an investment tool and does not promise any form of return or profit. Purchasing or holding this token is entirely voluntary and based solely on your love and support for Black Myth: Wukong.`,
            userPdes6: ` Risk Warning: The cryptocurrency market is highly volatile, and purchasing or holding meme tokens may carry certain risks. Please act cautiously and ensure that you fully understand and accept these risks.`,
            userPdes7: `    If you agree to and accept the above statements, please click the "Continue" button below.`,
            userPdes8: `I agree to and accept the above statements.`,
            userPdes9: `Continue`,
            COPY: "COPY",
            invalidCode: `Invalid product number`,
            invalidCodeDes: ` The product code you entered is invalid`,
            invalidCodeDes2: `Double-check the code you entered to ensure it was entered correctly.`,
            tryAgain: `Try it again`,
        },
    },
    tw: {
        translation: {
            HOME: "首頁",
            title1: "機構估計《黑神話：悟空》收藏數超400萬！70%來自中國",
            des1: "隨著8月20日的發售日期臨近，黑神話：悟空一直受到國內外玩家的關注，並一直名列Steam收藏榜首。但是，Steam後台並未顯示具體的收藏數。",
            title2: "box回應《黑神話：悟空》延期引發玩家熱議：索尼買斷獨家權？",
            des2: "本月初，Game Science宣布《黑神話：悟空》在Xbox Series X|S平台上的發售將延期。消息一出，立即引起廣泛關注和討論。許多人猜測延期的原因是由於Xbox Series S主機性能不足，導致開發團隊無法在預定時間內完成優化工作。",
            title3: "疑似粉絲自製《黑神話：悟空》MSI顯卡曝光，背板印有帥氣孫悟空",
            des3: "近日，網上曝光了一款《黑神話：悟空》的顯卡，這些定制顯卡的圖片是由用戶hongxing2020在X/Twitter上發布的。",
            title4: "微軟回應《黑神話》延期IGN：暗示索尼與遊客有獨家協議",
            des4: "本月初，Game Science確認《黑神話：悟空》XSX|S版本將因平台優化問題而延期。根據IGN的消息，Xbox官方今日對此事作出回應。",
            title5: "Gamerant試玩《黑神話：悟空》：物超所值並讓人期待更多",
            des5: "近日，黑神話：悟空在海外開展了線下試玩體驗活動，外媒Gamerant分享了其試玩體驗。在幾個小時的試玩體驗後，作者表示《黑神話》“物超所值”，與之前的多次展示一樣出色，是一款值得關注的遊戲。",
            backToHome: "返回首頁",
            new1des1:
                "隨著8月20日的發售日期臨近，黑神話：悟空一直受到國內外玩家的關注，並一直名列Steam收藏榜首。但是，Steam後台並未顯示具體的收藏數。",
            new1des2: ` 根據市場研究網站Game World Observer發布的最新報告，夏季遊戲節上發布了《黑神話：悟空》的最新預告片後，遊戲的Steam收藏數增加了約854,000，超過了6月7日至13日的遊戲展覽中展出的任何其他遊戲。報告估計，《黑神話：悟空》的收藏數已超過400萬，其中60%-70%的用戶來自中國。`,
            new1des3:
                "對於其他遊戲，文明7增加了593,000個收藏，七天增加了281,000個收藏，龍珠閃電零增加了182,000個收藏，毀滅：黑暗時代增加了138,000個收藏，而怪物獵人荒野增加了129,000個收藏。",
            new1des4: ` 研究網站通過將粉絲數乘以一定的倍數來估計Steam收藏數，通常是粉絲數的7到20倍（中位數為12倍）。因此，這不是準確的，僅供參考。`,
            new2des1: ` 本月初，Game Science宣布《黑神話：悟空》在Xbox Series X|S平台上的發售將延期。消息一出，立即引起廣泛關注和討論。許多人猜測延期的原因是由於Xbox Series S主機性能不足，導致開發團隊無法在預定時間內完成優化工作。`,
            new2des2: ` 面對外界各種猜測，Xbox官方最近發布了一份聲明，試圖澄清這個問題。`,
            new2des3: `在聲明中，Xbox表示：“我們很高興《黑神話：悟空》將在XSX|S上發布，我們正在與Game Science合作，將這款備受期待的遊戲帶到我們的平台上。我們不能對我們的合作夥伴與其他平台持有者達成的協議發表評論。但我們仍專注於使Xbox成為玩家最好的平台，優秀的遊戲是其核心。”`,
            new2des4: ` 雖然聲明看似平靜，但細心的玩家在其中找到了暗示。一些玩家認為Xbox在聲明中提到的“與其他平台持有者達成的協議”實際上是在暗示索尼可能已經與Game Science達成某種獨家協議。這一觀點迅速在社交媒體上引發了熱烈討論。一位用戶評論說：“所以，@JezCorden是對的，索尼確實瘋狂簽署獨家協議。”另一位用戶說：“PlayStation再次支付有限時間的獨家權。如果Xbox這樣做，他們肯定會被嚴厲斥責。”`,
            new2des5: ` 同時，一些玩家認為Xbox的聲明是在推卸責任。一位玩家評論說：“這有點諷刺。這暗示這是一筆交易，而不是因為Xbox Series S的主機性能要求。”另一位用戶更加直言不諱：“索尼將繼續採取這種不公平的競爭方式來保持其在遊戲機市場的領先地位，因為他們知道如何競爭。微軟/Xbox將繼續免費贈送遊戲，這個平台將變得越來越不吸引玩家和工作室，銷售將繼續下降。”`,
            new2des6: `  一些玩家對Xbox的回應表示不滿，認為它試圖將自己的問題歸咎於競爭對手。一位用戶諷刺地說：“看看微軟試圖將問題歸咎於索尼，哈哈哈。”`,
            new2des7: `《黑神話：悟空》將於8月20日正式發布，並將在PC和PS5平台上提供。《黑神話：悟空》XSX|S版本的具體發布日期仍不明確。希望隨著時間的推移，Game Science和Xbox能夠給玩家帶來更多積極的消息。`,
            new3des1: `最近，網上曝光了一款《黑神話：悟空》的顯卡，這些定制顯卡的圖片是由用戶hongxing2020在X/Twitter上發布的。`,
            new3des2: ` 與其他MSI合作產品不同，這款顯卡沒有太多個性化的模型，只在背板上印有主角“天命人”的圖片。這可能更像是一個個性化的粉絲作品，而不是官方產品。`,
            new3des3: ` 當科技網站Videocardz問原始發布者這是“定制還是新型號”時，用戶hongxing2020回答“可能是...”，這更加增加了猜測。`,
            new3des4: `《黑神話：悟空》預計將於2024年8月20日發布，因此我們希望在接下來的幾周內能有確定的消息。`,
            new4des1: `本月初，Game Science確認《黑神話：悟空》XSX|S版本將因平台優化問題而延期。根據IGN的消息，Xbox官方今日對此事作出回應。`,
            new4des2: ` “我們很高興《黑神話：悟空》將在XSX|S上發布，我們正在與Game Science合作，將這款遊戲帶到我們的平台上。我們不能對我們的合作夥伴與其他平台持有者達成的協議發表評論，但我們仍專注於使Xbox成為玩家最好的平台，優秀的遊戲是其核心。”`,
            new4des3: ` 此外，IGN還提到了Game Science可能已經與索尼達成獨家協議的傳聞，並認為微軟可能在這份聲明中暗示了這一點。`,
            new5des1: `最近，黑神話：悟空在海外開展了線下試玩體驗活動，外國媒體Gamerant分享了其試玩體驗。在幾個小時的試玩體驗後，作者表示黑神話“物超所值”，與之前的多次展示一樣出色，是一款值得關注的遊戲。`,
            new5des2: `文章指出，黑神話不是一款正統的靈魂遊戲。這款遊戲的戰鬥速度快節奏而激烈，沒有傳統的招架和格擋，遊戲中閃避尤為重要。此外，靈魂遊戲的世界更加開放，而黑神話的前幾個小時似乎相當線性。在角色成長方面，黑神話通過標準技能樹和獲得的能力來提升，而不是通過花費點數來增強力量和耐力等屬性。`,
            new5des3: ` 組合招式可以給敵人造成巨大傷害，混合充能重擊可以讓玩家以不同的方式展示自己的技能。玩家需要冒險造成傷害，但也需要在必要時閃避。做出選擇的過程是一種迷人的體驗。並且隨著法術和變身的加入，遊戲將變得更有趣。`,
            new5des4: ` 作者指出，Game Science成功地改編了《西遊記》，並創造了一些獨特而令人難忘的敵人。作者在3小時內遇到了至少5個boss，有些喜歡進攻，有些防守，但大多數既進攻又防守。擊敗boss將獲得一些獨特的變身能力，這對孫悟空的進化至關重要。`,
            new5des5: `最後，作者表示，試玩內容最好的獎勵是“它能讓人想要更多”，而《黑神話》已經做到了。他寫道：“我想看到更多令人難忘的世界，提高我的技能，挑戰更多的boss，體驗戰鬥的進化。”黑神話確實擁有令人印象深刻的戰鬥和美麗的圖形。這是真正的交易，而不是噱頭。這是我在2024年下半年最期待的遊戲之一。”`,
            source: "來源",
            edit: "編輯",
            EXCHANGE: "兑换",
            OVERVIEW: "故事",
            o1des1: `在西遊記之後，妖魔互相爭鬥。上一代的“天道”和悟空領導的妖魔聯盟成功擊敗了佛教和道教門派，成為了三界的新統治階級。法教末劫來臨，如來涅槃，彌勒再世，道教隱世。但慢慢地悟空發現了一些不對勁，他似乎被利用了，世界並沒有像他想象的那樣變得更好，而是越來越糟，所以他充滿了自責和內疚，`,
            o1des2: `開始尋找一條新的拯救世界的道路。`,
            TOKEPMIC: "TOKENMIC",
            t1des1: `激勵`,
            t1des2: ` Cex列表`,
            t1des3: ` 公平發行`,
            PROGRAM: "激勵計劃",
            p1des1: `通過完成《黑神話：悟空》的遊戲，玩家有助於`,
            p1des2: `推廣成就系統。`,
            p1des3: `他們可以將成就系統的徽章交易為空投。`,
            p1des4: `根據成就難度程度發布分層空投獎勵。`,
            p1des5: `如果所有成就都完成，將獎勵額外激勵。`,
            returnInfo: `< 返回信息主頁`,
            gameInfo: `遊戲资讯`,
            TOKENOMICS: `代幣經濟模型`,
            e1des: `请输入《黑神话：悟空》兑换码`,
            enter: `兑换`,
            congratulations: `恭喜！您已成功兌換`,
            s1des1: `前往Steam平台激活您的遊戲。請勿將激活碼發送給騙子（我們不對您的錯誤操作造成的損失負責）`,
            ok: "好",
            userPrivacy: `用戶協議和隱私政策`,
            userPdes1: `歡迎支持和關注遊戲黑神話：悟空！$wukong旨在通過社區努力推廣和宣傳這款遊戲，並通過獎勵玩家的遊戲內活動。在繼續之前，請仔細閱讀以下聲明：`,
            userPdes2: `社區代幣性質：這個代幣是由黑神話：悟空的一群愛好者社區自發發行的，而不是由官方遊戲開發者發行。它的目的是通過社區努力推廣和宣傳遊戲，並獎勵玩家的遊戲內活動，而不是具有任何實際的貨幣價值。`,
            userPdes3: `非官方代幣：這個代幣與黑神話：悟空的開發者或官方團隊無關。官方遊戲開發者不對這個代幣的發行、使用或任何相關活動負責。`,
            userPdes4: `獎勵性質：這個代幣旨在獎勵玩家的遊戲內活動。持有或使用這個代幣的玩家可能在社區內獲得特殊獎勵或榮譽，但不承諾任何形式的回報或利潤。`,
            userPdes5: `非投資工具：這個代幣不是投資工具，也不承諾任何形式的回報或利潤。購買或持有這個代幣完全是自願的，僅基於您對黑神話：悟空的愛和支持。`,
            userPdes6: `風險警告：加密貨幣市場極其波動，購買或持有模因代幣可能存在一定風險。請謹慎行事，確保您充分了解並接受這些風險。`,
            userPdes7: `如果您同意並接受上述聲明，請點擊下面的“繼續”按鈕。`,
            userPdes8: `我同意並接受上述聲明。`,
            userPdes9: `繼續`,
            COPY: "複製",
            invalidCode: `無效的產品號`,
            invalidCodeDes: `您輸入的產品代碼無效`,
            invalidCodeDes2: `請仔細檢查您輸入的代碼，以確保輸入正確。`,
            tryAgain: `再試一次`,
        },
    },
    zh: {
        translation: {
            HOME: "首页",
            title1: "机构估计《黑神话：悟空》收藏数超400万！70%来自中国",
            des1: "随着8月20日的发售日期临近，黑神话：悟空一直受到国内外玩家的关注，并一直名列Steam收藏榜首。但是，Steam后台并未显示具体的收藏数。",
            title2: "box回应《黑神话：悟空》延期引发玩家热议：索尼买断独家权？",
            des2: "本月初，Game Science宣布《黑神话：悟空》在Xbox Series X|S平台上的发售将延期。消息一出，立即引起广泛关注和讨论。许多人猜测延期的原因是由于Xbox Series S主机性能不足，导致开发团队无法在预定时间内完成优化工作。",
            title3: "疑似粉丝自制《黑神话：悟空》MSI显卡曝光，背板印有帅气孙悟空",
            des3: "近日，网上曝光了一款《黑神话：悟空》的显卡，这些定制显卡的图片是由用户hongxing2020在X/Twitter上发布的。",
            title4: "微软回应《黑神话》延期IGN：暗示索尼与游客有独家协议",
            des4: "本月初，Game Science确认《黑神话：悟空》XSX|S版本将因平台优化问题而延期。根据IGN的消息，Xbox官方今日对此事作出回应。",
            title5: "Gamerant试玩《黑神话：悟空》：物超所值并让人期待更多",
            des5: "近日，黑神话：悟空在海外开展了线下试玩体验活动，外媒Gamerant分享了其试玩体验。在几个小时的试玩体验后，作者表示《黑神话》“物超所值”，与之前的多次展示一样出色，是一款值得关注的游戏。",
            backToHome: "返回首页",
            new1des1:
                "随着8月20日的发售日期临近，黑神话：悟空一直受到国内外玩家的关注，并一直名列Steam收藏榜首。但是，Steam后台并未显示具体的收藏数。",
            new1des2: ` 根据市场研究网站Game World Observer发布的最新报告，夏季游戏节上发布了《黑神话：悟空》的最新预告片后，游戏的Steam收藏数增加了约854,000，超过了6月7日至13日的游戏展览中展出的任何其他游戏。报告估计，《黑神话：悟空》的收藏数已超过400万，其中60%-70%的用户来自中国。`,
            new1des3:
                "对于其他游戏，文明7增加了593,000个收藏，七天增加了281,000个收藏，龙珠闪电零增加了182,000个收藏，毁灭：黑暗时代增加了138,000个收藏，而怪物猎人荒野增加了129,000个收藏。",
            new1des4: ` 研究网站通过将粉丝数乘以一定的倍数来估计Steam收藏数，通常是粉丝数的7到20倍（中位数为12倍）。因此，这不是准确的，仅供参考。`,
            new2des1: ` 本月初，Game Science宣布《黑神话：悟空》在Xbox Series X|S平台上的发售将延期。消息一出，立即引起广泛关注和讨论。许多人猜测延期的原因是由于Xbox Series S主机性能不足，导致开发团队无法在预定时间内完成优化工作。`,
            new2des2: ` 面对外界各种猜测，Xbox官方最近发布了一份声明，试图澄清这个问题。`,
            new2des3: ` 在声明中，Xbox表示：“我们很高兴《黑神话：悟空》将在XSX|S上发布，我们正在与Game Science合作，将这款备受期待的游戏带到我们的平台上。我们不能对我们的合作伙伴与其他平台持有者达成的协议发表评论，但我们仍专注于使Xbox成为玩家最好的平台，优秀的游戏是其核心。”`,
            new2des4: ` 虽然声明看似平静，但细心的玩家在其中找到了暗示。一些玩家认为Xbox在声明中提到的“与其他平台持有者达成的协议”实际上是在暗示索尼可能已经与Game Science达成某种独家协议。这一观点迅速在社交媒体上引发了热烈讨论。一位用户评论说：“所以，@JezCorden是对的，索尼确实疯狂签署独家协议。”另一位用户说：“PlayStation再次支付有限时间的独家权。如果Xbox这样做，他们肯定会被严厉斥责。”`,
            new2des5: ` 同时，一些玩家认为Xbox的声明是在推卸责任。一位玩家评论说：“这有点讽刺。这暗示这是一笔交易，而不是因为Xbox Series S的主机性能要求。”另一位用户更加直言不讳：“索尼将继续采取这种不公平的竞争方式来保持其在游戏机市场的领先地位，因为他们知道如何竞争。微软/Xbox将继续免费赠送游戏，这个平台将变得越来越不吸引玩家和工作室，销售将继续下降。”`,
            new2des6: ` 一些玩家对Xbox的回应表示不满，认为它试图将自己的问题归咎于竞争对手。一位用户讽刺地说：“看看微软试图将问题归咎于索尼，哈哈哈。”`,
            new2des7: ` 《黑神话：悟空》将于8月20日正式发布，并将在PC和PS5平台上提供。《黑神话：悟空》XSX|S版本的具体发布日期仍不明确。希望随着时间的推移，Game Science和Xbox能够给玩家带来更多积极的消息。`,
            new3des1: ` 最近，网上曝光了一款《黑神话：悟空》的显卡，这些定制显卡的图片是由用户hongxing2020在X/Twitter上发布的。`,
            new3des2: ` 与其他MSI合作产品不同，这款显卡没有太多个性化的模型，只在背板上印有主角“天命人”的图片。这可能更像是一个个性化的粉丝作品，而不是官方产品。`,
            new3des3: ` 当科技网站Videocardz问原始发布者这是“定制还是新型号”时，用户hongxing2020回答“可能是...”，这更加增加了猜测。`,
            new3des4: ` 《黑神话：悟空》预计将于2024年8月20日发布，因此我们希望在接下来的几周内能有确定的消息。`,
            new4des1: ` 本月初，Game Science确认《黑神话：悟空》XSX|S版本将因平台优化问题而延期。根据IGN的消息，Xbox官方今日对此事作出回应。`,
            new4des2: ` “我们很高兴《黑神话：悟空》将在XSX|S上发布，我们正在与Game Science合作，将这款游戏带到我们的平台上。我们不能对我们的合作伙伴与其他平台持有者达成的协议发表评论，但我们仍专注于使Xbox成为玩家最好的平台，优秀的游戏是其核心。”`,
            new4des3: ` 此外，IGN还提到了Game Science可能已经与索尼达成独家协议的传闻，并认为微软可能在这份声明中暗示了这一点。`,
            new5des1: ` 最近，黑神话：悟空在海外开展了线下试玩体验活动，外国媒体Gamerant分享了其试玩体验。在几个小时的试玩体验后，作者表示黑神话“物超所值”，与之前的多次展示一样出色，是一款值得关注的游戏。`,
            new5des2: ` 文章指出，黑神话不是一款正统的灵魂游戏。这款游戏的战斗速度快节奏而激烈，没有传统的招架和格挡，游戏中闪避尤为重要。此外，灵魂游戏的世界更加开放，而黑神话的前几个小时似乎相当线性。在角色成长方面，黑神话通过标准技能树和获得的能力来提升，而不是通过花费点数来增强力量和耐力等属性。`,
            new5des3: `     组合招式可以给敌人造成巨大伤害，混合充能重击可以让玩家以不同的方式展示自己的技能。玩家需要冒险造成伤害，但也需要在必要时闪避。做出选择的过程是一种迷人的体验。并且随着法术和变身的加入，游戏将变得更有趣。`,
            new5des4: ` 作者指出，Game Science成功地改编了《西游记》，并创造了一些独特而令人难忘的敌人。作者在3小时内遇到了至少5个boss，有些喜欢进攻，有些防守，但大多数既进攻又防守。击败boss将获得一些独特的变身能力，这对孙悟空的进化至关重要。`,
            new5des5: ` 最后，作者表示，试玩内容最好的奖励是“它能让人想要更多”，而《黑神话》已经做到了。他写道：“我想看到更令人难忘的世界，提高我的技能，挑战更多的boss，体验战斗的进化。”黑神话确实拥有令人印象深刻的战斗和美丽的图形。这是真正的交易，而不是噱头。这是我在2024年下半年最期待的游戏之一。”`,
            source: "来源",
            edit: "编辑",
            EXCHANGE: "兑换",
            OVERVIEW: "故事",
            o1des1: `在西游记之后，妖魔互相争斗。上一代的“天道”和悟空领导的妖魔联盟成功击败了佛教和道教门派，成为了三界的新统治阶级。法教末劫来临，如来涅槃，弥勒再世，道教隐世。但慢慢地悟空发现了一些不对劲，他似乎被利用了，世界并没有像他想象的那样变得更好，而是越来越糟，所以他充满了自责和内疚，`,
            o1des2: `开始寻找一条新的拯救世界的道路。`,
            TOKEPMIC: "TOKENMIC",
            t1des1: `激励`,
            t1des2: ` Cex列表`,
            t1des3: ` 公平发行`,
            PROGRAM: "激励计划",
            p1des1: `通过完成《黑神话：悟空》的游戏，玩家有助于`,
            p1des2: `推广成就系统。`,
            p1des3: `他们可以将成就系统的徽章交易为空投。`,
            p1des4: `根据成就难度程度发布分层空投奖励。`,
            p1des5: `如果所有成就都完成，将奖励额外激励。`,
            returnInfo: `< 返回信息主页`,
            gameInfo: `游戏资讯`,
            TOKENOMICS: `代币经济模型`,
            e1des: `请输入《黑神话：悟空》兑换码`,
            enter: `兑换`,
            congratulations: `恭喜！您已成功兑换`,
            s1des1: `前往Steam平台激活您的游戏。请勿将激活码发送给骗子（我们不对您的错误操作造成的损失负责）`,
            ok: "好",
            userPrivacy: `用户协议和隐私政策`,
            userPdes1: `欢迎支持和关注游戏黑神话：悟空！$wukong旨在通过社区努力推广和宣传这款游戏，并通过奖励玩家的游戏内活动。在继续之前，请仔细阅读以下声明：`,
            userPdes2: `社区代币性质：这个代币是由黑神话：悟空的一群爱好者社区自发发行的，而不是由官方游戏开发者发行。它的目的是通过社区努力推广和宣传游戏，并奖励玩家的游戏内活动，而不是具有任何实际的货币价值。`,
            userPdes3: `非官方代币：这个代币与黑神话：悟空的开发者或官方团队无关。官方游戏开发者不对这个代币的发行、使用或任何相关活动负责。`,
            userPdes4: `奖励性质：这个代币旨在奖励玩家的游戏内活动。持有或使用这个代币的玩家可能在社区内获得特殊奖励或荣誉，但不承诺任何形式的回报或利润。`,
            userPdes5: `非投资工具：这个代币不是投资工具，也不承诺任何形式的回报或利润。购买或持有这个代币完全是自愿的，仅基于您对黑神话：悟空的爱和支持。`,
            userPdes6: `风险警告：加密货币市场极其波动，购买或持有模因代币可能存在一定风险。请谨慎行事，确保您充分了解并接受这些风险。`,
            userPdes7: `如果您同意并接受上述声明，请点击下面的“继续”按钮。`,
            userPdes8: `我同意并接受上述声明。`,
            userPdes9: `继续`,
            COPY: "复制",
            invalidCode: `无效的产品号`,
            invalidCodeDes: `您输入的产品代码无效`,
            invalidCodeDes2: `请仔细检查您输入的代码，以确保输入正确。`,
            tryAgain: `再试一次`,
        },
    },
    ko: {
        translation: {
            HOME: "홈",
            title1: "기관은 블랙 신화의 위시리스트 수가 400 만 명을 초과한다고 추정합니다! 70%는 중국에서 왔습니다",
            des1: "8 월 20 일 출시일이 점점 가까워지면서 블랙 신화 : 웨이콩은 국내외 플레이어들의 큰 관심을 끌고 있으며 스팀 위시리스트 수집 순위에서 항상 1 위를 차지하고 있습니다. 그러나 스팀 백엔드에는 구체적인 수집 수가 표시되지 않습니다.",
            title2: '박스는 "블랙 신화 : 웨이콩"의 지연에 대한 응답으로 플레이어들 사이에서 열띤 논의를 불러일으켰습니다 : 소니가 독점 권리를 사들였습니까?',
            des2: "이달 초에 게임 과학은 Xbox Series X|S 플랫폼에서 블랙 신화 : 웨이콩의 출시가 지연될 것이라고 발표했습니다. 이 소식이 나오자마자 많은 관심과 논의를 불러일으켰습니다. 많은 사람들은 지연의 이유가 Xbox Series S 콘솔의 성능이 부족하여 개발 팀이 예정된 시간 내에 최적화 작업을 완료하지 못했기 때문이라고 추측했습니다.",
            title3: '의심스러운 팬 제작 MSI 그래픽 카드 "블랙 신화 : 웨이콩" 노출, 멋진 원콩이 뒷패널에 인쇄되어 있습니다',
            des3: '최근 인터넷에서 "블랙 신화 : 웨이콩"용 그래픽 카드가 발견되었습니다. 이러한 맞춤형 그래픽 카드의 사진은 사용자 hongxing2020이 X/Twitter에 게시한 것입니다.',
            title4: "마이크로소프트는 블랙 신화의 지연에 대한 IGN의 응답 : 소니와 유케가 독점 계약을 했다는 신호",
            des4: '이달 초에 게임 과학은 XSX|S 버전의 "블랙 신화 : 웨이콩"이 플랫폼에서 최적화 문제로 인해 지연될 것이라고 확인했습니다. IGN에 따르면 Xbox 측은 오늘 이 문제에 대해 응답했습니다.',
            title5: "Gamerant가 블랙 신화 : 웨이콩을 시도해보다 : 그것은 정말 싸고 더 많은 것을 원하게 만듭니다",
            des5: '최근 블랙 신화 : 웨이콩은 해외에서 오프라인 체험 이벤트를 시작했으며 외국 매체 Gamerant는 체험 후기를 공유했습니다. 몇 시간의 체험 후에 작가는 블랙 신화가 "정말 좋다"고 말하며 이전의 많은 시연과 같이 훌륭한 게임이며 주목할 가치가 있다고 말했습니다.',
            backToHome: "홈으로 돌아가기",
            new1des1:
                "8 월 20 일 출시일이 점점 가까워지면서 블랙 신화 : 웨이콩은 국내외 플레이어들의 큰 관심을 끌고 있으며 스팀 위시리스트 수집 순위에서 항상 1 위를 차지하고 있습니다. 그러나 스팀 백엔드에는 구체적인 수집 수가 표시되지 않습니다.",
            new1des2: ` 시장 조사 사이트 Game World Observer가 최근 발표한 보고서에 따르면, 여름 게임 축제에서 블랙 신화 : 웨이콩의 최신 예고편이 공개된 후 게임의 스팀 위시리스트 수가 약 854,000 개 증가하여 6 월 7 일부터 13 일까지 전시된 다른 게임보다 많아졌습니다. 보고서는 블랙 신화 : 웨이콩의 위시리스트 수가 이미 400 만 명을 넘었으며 그 중 60% ~ 70%가 중국에서 사용자라고 추정합니다.`,
            new1des3:
                "다른 게임의 경우, 문명 7은 593,000 개의 위시리스트를 추가했으며 세븐데이즈는 281,000 개의 위시리스트를 추가했으며 드래곤볼 라이징 제로는 182,000 개의 위시리스트를 추가했으며 파괴 : 어둠의 시대는 138,000 개의 위시리스트를 추가했으며 몬스터 헌터 라이즈는 129,000 개의 위시리스트를 추가했습니다.",
            new1des4: ` 연구 사이트는 팬 수를 일정한 배수로 곱하여 스팀 위시리스트 수를 추정합니다. 일반적으로 팬 수의 7 ~ 20 배 (중앙값은 12 배)입니다. 따라서 이것은 정확하지 않으며 참고용입니다.`,
            new2des1: ` 이달 초에 게임 과학은 XSX|S 플랫폼에서 블랙 신화 : 웨이콩의 출시가 지연될 것이라고 발표했습니다. 이 소식이 나오자마자 많은 관심과 논의를 불러일으켰습니다. 많은 사람들은 지연의 이유가 Xbox Series S 콘솔의 성능이 부족하여 개발 팀이 예정된 시간 내에 최적화 작업을 완료하지 못했기 때문이라고 추측했습니다.`,
            new2des2: ` 외부의 다양한 추측에 직면하여 Xbox 측은 최근 성명서를 발표하여 이 문제를 명확히하려고 노력했습니다.`,
            new2des3: ` 성명서에서 Xbox는 "우리는 블랙 신화 : 웨이콩이 XSX|S에서 출시될 것을 기대하고 있으며 이 기대되는 게임을 우리 플랫폼으로 가져오기 위해 Game Science와 협력하고 있습니다. 우리는 우리의 파트너가 다른 플랫폼 소유자와 달성한 계약에 대해 의견을 제시할 수 없지만 여전히 Xbox를 최고의 플랫폼으로 만들기 위해 집중하고 있으며 우수한 게임은 그 핵심입니다."`,
            new2des4: ` 성명서는 평온해 보이지만 세심한 플레이어들은 그 안에 힌트를 찾을 수 있습니다. 일부 플레이어들은 Xbox가 성명서에서 언급한 "다른 플랫폼 소유자와 달성한 계약"이 실제로 소니가 Game Science와 어떤 형태의 독점 계약을 체결했을 수 있다는 것을 의미한다고 생각합니다. 이견은 소셜 미디어에서 빠르게 논란이 되었습니다. 한 사용자는 "그래서 @JezCorden이 맞다. 소니는 정말 독점 계약을 맺었다."라고 코멘트했으며 다른 사용자는 "플레이스테이션은 다시 한 번 제한된 시간의 독점 권리를 지불했습니다. Xbox가 이렇게 한다면 분명히 엄격히 비난받을 것입니다."라고 말했습니다.`,
            new2des5: ` 동시에 일부 플레이어들은 Xbox의 성명서가 책임을 회피하려는 것이라고 생각합니다. 한 사용자는 "이것은 약간 풍자적입니다. 이것은 거래임을 시사하고 있으며 Xbox Series S의 주요 성능 요구 사항 때문이 아닙니다."라고 코멘트했으며 다른 사용자는 더 직접적으로 말했습니다. "소니는 게임 기계 시장에서 선두 지위를 유지하기 위해 이러한 불공정한 경쟁 방식을 계속할 것입니다. 그들은 경쟁하는 방법을 알고 있기 때문입니다. 마이크로소프트/Xbox는 계속해서 게임을 무료로 제공할 것이며 이 플랫폼은 점점 더 매력적이지 않을 것이며 판매는 계속해서 감소할 것입니다."`,
            new2des6: ` 일부 플레이어들은 Xbox의 응답에 불만을 표시하며 자신의 문제를 경쟁 상대에게 돌리려는 것으로 생각합니다. 한 사용자는 풍자적으로 말했습니다. "마이크로소프트가 문제를 소니에게 돌리려고 노력하는 것을 보세요. ㅋㅋㅋ."`,
            new2des7: ` "블랙 신화 : 웨이콩"은 8 월 20 일에 공식적으로 출시되며 PC 및 PS5 플랫폼에서 제공될 예정입니다. "블랙 신화 : 웨이콩" XSX|S 버전의 구체적인 출시일은 아직 명확하지 않습니다. 시간이 지남에 따라 Game Science와 Xbox가 플레이어에게 더 많은 긍정적인 소식을 전해줄 수 있기를 바랍니다.`,
            new3des1: ` 최근 인터넷에서 "블랙 신화 : 웨이콩"용 그래픽 카드가 발견되었습니다. 이러한 맞춤형 그래픽 카드의 사진은 사용자 hongxing2020이 X/Twitter에 게시한 것입니다.`,
            new3des2: ` 다른 MSI 협력 제품과 달리이 그래픽 카드는 많은 개성화 모델이 없으며 주인공 "천명인"의 이미지가 뒷패널에 인쇄되어 있습니다. 이것은 공식 제품이 아닌 팬 제작품일 수 있습니다.`,
            new3des3: ` 기술 사이트 Videocardz가 원래 게시자에게 "맞춤인가 새로운 모델인가"라고 물었을 때 사용자 hongxing2020은 "아마도..."라고 답했으며 이는 추측을 더욱 증폭시켰습니다.`,
            new3des4: ` "블랙 신화 : 웨이콩"은 2024 년 8 월 20 일에 출시될 예정이므로 앞으로 몇 주 안에 확실한 소식을 기대합니다.`,
            new4des1: ` 이달 초에 게임 과학은 XSX|S 버전의 "블랙 신화 : 웨이콩"이 플랫폼에서 최적화 문제로 인해 지연될 것이라고 확인했습니다. IGN에 따르면 Xbox 측은 오늘 이 문제에 대해 응답했습니다.`,
            new4des2: ` "우리는 블랙 신화 : 웨이콩이 XSX|S에서 출시될 것을 기대하고 있으며 이 기대되는 게임을 우리 플랫폼으로 가져오기 위해 Game Science와 협력하고 있습니다. 우리는 우리의 파트너가 다른 플랫폼 소유자와 달성한 계약에 대해 의견을 제시할 수 없지만 여전히 Xbox를 최고의 플랫폼으로 만들기 위해 집중하고 있으며 우수한 게임은 그 핵심입니다."`,
            new4des3: ` 뿐만 아니라 IGN은 Game Science가 이미 소니와 독점 계약을 체결했을 수 있다는 소문을 언급하며 마이크로소프트가 이 성명서에서 이를 시사했을 수 있다고 생각합니다.`,
            new5des1: ` 최근 블랙 신화 : 웨이콩은 해외에서 오프라인 체험 이벤트를 시작했으며 외국 매체 Gamerant는 체험 후기를 공유했습니다. 몇 시간의 체험 후에 작가는 블랙 신화가 "정말 좋다"고 말하며 이전의 많은 시연과 같이 훌륭한 게임이며 주목할 가치가 있다고 말했습니다.`,
            new5des2: ` 기사는 블랙 신화가 전통적인 소울즈 게임이 아니라고 지적합니다. 이 게임의 전투 속도는 빠르고 격렬하며 전통적인 막기와 방어가 없으며 피하는 것이 중요합니다. 또한 소울즈 게임의 세계는 더욱 개방적이며 블랙 신화의 처음 몇 시간은 상당히 선형적인 것으로 보입니다. 캐릭터 성장 측면에서 블랙 신화는 표준 스킬 트리와 획득한 능력을 통해 향상시키며 포인트를 사용하여 힘과 스태미너 등 속성을 강화하지 않습니다.`,
            new5des3: ` 조합 기술은 적에게 큰 피해를 입힐 수 있으며 혼합 충전 공격은 플레이어가 자신의 기술을 다양한 방식으로 표현할 수 있게 합니다. 플레이어는 피해를 입히기 위해 모험을 하지만 필요할 때 피하는 것이 중요합니다. 선택을 하는 과정은 매력적인 경험입니다. 그리고 주문과 변신이 추가되면 게임은 더욱 재미있어질 것입니다.`,
            new5des4: ` 작가는 Game Science가 "서유기"를 성공적으로 적응시키고 독특하고 잊을 수 없는 적을 창조했다고 지적합니다. 작가는 3 시간 동안 적어도 5 명의 보스를 만났으며 공격적인 것도 있고 방어적인 것도 있지만 대부분은 공격적이자 방어적입니다. 보스를 물리치면 특별한 변신 능력을 얻게 되며 이는 원콩의 진화에 중요합니다.`,
            new5des5: ` 마지막으로 작가는 체험 콘텐츠의 최고의 보상은 "더 많이 원한다"는 것이며 블랙 신화가 그것을 달성했다고 말합니다. 그는 "더욱 기억에 남는 세계를 보고 싶고 기술을 향상시키고 더 많은 보스에 도전하며 전투의 진화를 경험하고 싶습니다."라고 썼으며 블랙 신화는 실제 거래이며 광고가 아닙니다. 이것은 2024년 하반기에 가장 기대되는 게임 중 하나입니다.`,
            source: "출처",
            edit: "편집",
            EXCHANGE: "교환",
            OVERVIEW: "스토리",
            o1des1: `서유기 이후, 요마들은 서로 싸우고 있습니다. 이전 세대의 "천도"와 웨이콩이 이끄는 요마 연맹은 불교와 도교 선파를 성공적으로 물리쳤으며 세계의 새로운 통치 계급이 되었습니다. 법교 말기가 오고, 라이라이가 부활하고, 도교가 은세합니다. 그러나 웨이콩은 조금씩 이상한 것을 발견하고 있습니다. 그는 자신이 이용당한 것처럼 보이며 세상이 더 나아지는 대신 더 나빠지고 있다는 것을 깨닫기 시작했습니다. 그래서 그는 자책과 죄책감에 가득 차,`,
            o1des2: `세상을 구하는 새로운 길을 찾기 시작합니다.`,
            TOKEPMIC: "TOKENMIC",
            t1des1: `인센티브`,
            t1des2: `Cex 목록`,
            t1des3: `공정한 발행`,
            PROGRAM: "프로그램",
            p1des1: `《블랙 신화 : 웨이콩》 게임을 완료함으로써 플레이어는`,
            p1des2: `업적 시스템을 홍보하는 데 도움이 됩니다.`,
            p1des3: `업적 시스템의 뱃지를 공짜로 받을 수 있습니다.`,
            p1des4: `업적 난이도에 따라 계층적 공짜 보상을 발행합니다.`,
            p1des5: `모든 업적을 완료하면 추가 인센티브를 받습니다.`,
            returnInfo: `< 정보 홈으로 돌아가기`,
            gameInfo: `게임 정보`,
            TOKENOMICS: `토큰 노믹스`,
            e1des: `게임 코드를 등록해주세요`,
            enter: `환전`,
            congratulations: `축하합니다! 성공적으로 교환했습니다`,
            s1des1: `스팀 플랫폼으로 이동하여 게임을 활성화하십시오. 활성화 코드를 사기꾼에게 보내지 마십시오 (당사는 사용자의 실수로 인한 손실에 대해 책임지지 않습니다)`,
            ok: "확인",
            userPrivacy: `사용자 계약 및 개인 정보 보호 정책`,
            userPdes1: `게임 블랙 신화 : 웨이콩을 지원하고 주목해 주셔서 감사합니다! $wukong은 이 게임을 커뮤니티 노력을 통해 홍보하고 홍보하고 게임 내 활동을 보상하는 데 중점을 둡니다. 계속하기 전에 다음 성명서를 주의 깊게 읽어보십시오.`,
            userPdes2: `커뮤니티 토큰의 성격 : 이 토큰은 블랙 신화 : 웨이콩의 팬 커뮤니티가 자발적으로 발행한 것이며 공식 게임 개발자가 발행한 것이 아닙니다. 이것은 게임을 커뮤니티 노력을 통해 홍보하고 홍보하고 게임 내 활동을 보상하는 데 중점을 둡니다. 실제 화폐 가치가 없습니다.`,
            userPdes3: `비공식 토큰 : 이 토큰은 블랙 신화 : 웨이콩의 개발자나 공식 팀과 관련이 없습니다. 공식 게임 개발자는 이 토큰의 발행, 사용 또는 관련 활동에 대해 책임지지 않습니다.`,
            userPdes4: `보상의 성격 : 이 토큰은 게임 내 활동을 보상하기 위한 것입니다. 이 토큰을 보유하거나 사용하는 플레이어는 커뮤니티 내에서 특별한 보상이나 명예를 받을 수 있지만 어떤 형태의 보상이나 이익도 보장하지 않습니다.`,
            userPdes5: `투자 도구가 아님 : 이 토큰은 투자 도구가 아니며 어떤 형태의 보상이나 이익도 보장하지 않습니다. 이 토큰을 구매하거나 보유하는 것은 완전히 자발적이며 블랙 신화 : 웨이콩에 대한 지지와 사랑에 기반합니다.`,
            userPdes6: `위험 경고 : 암호화폐 시장은 매우 변동적이며 이 토큰을 구매하거나 보유하는 것은 일정한 위험이 있을 수 있습니다. 신중하게 행동하고 이러한 위험을 충분히 이해하고 수용하십시오.`,
            userPdes7: `위의 성명서에 동의하고 수락하신다면 아래의 "계속" 버튼을 클릭하십시오.`,
            userPdes8: `동의하고 수락합니다.`,
            userPdes9: `계속`,
            COPY: "복사",
            invalidCode: `유효하지 않은 제품 코드`,
            invalidCodeDes: `입력한 제품 코드가 유효하지 않습니다`,
            invalidCodeDes2: `입력한 코드가 올바른지 확인하십시오.`,
            tryAgain: `다시 시도하십시오`,
        },
    },
    ja: {
        translation: {
            HOME: "ホーム",
            title1: "機関は、ブラック神話のウィッシュリストの数が400万人を超えると推定しています！ 70％は中国からです",
            des1: "8月20日の発売日が近づくにつれて、ブラック神話：悟空は国内外のプレイヤーから注目を集め、常にSteamのウィッシュリストコレクションランキングで1位にランクインしています。 ただし、Steamバックエンドには具体的なコレクション数が表示されません。",
            title2: "boxは、プレイヤーの間で議論を巻き起こした「ブラック神話：悟空」の遅延に対する応答：ソニーが独占権を買ったのですか？",
            des2: "今月初め、Game Scienceは、Xbox Series X|Sプラットフォームでのブラック神話：悟空のリリースが延期されると発表しました。 このニュースが出ると、広範な関心と議論を呼びました。 多くの人々は、遅延の理由がXbox Series Sコンソールの性能が不十分であり、開発チームが予定された時間内に最適化作業を完了できなかったためだと推測しました。",
            title3: "疑わしいファン製MSIグラフィックカード「ブラック神話：悟空」が公開され、バックパネルにはイケメン孫悟空が印刷されています",
            des3: "最近、インターネットで「ブラック神話：悟空」のグラフィックカードが発見されました。 これらのカスタマイズされたグラフィックカードの画像は、ユーザーhongxing2020によってX/Twitterに投稿されました。",
            title4: "マイクロソフトはブラック神話の遅延に対するIGNの回答：ソニーとユーケが独占契約を結んだことを示唆",
            des4: "今月初め、Game Scienceは、プラットフォームの最適化問題により「ブラック神話：悟空」のXSX|Sバージョンが遅れると確認しました。 IGNによると、Xboxの公式は今日、この問題に対して回答しました。",
            title5: "Gamerantがブラック神話：悟空を試してみる：それは本当にお得であり、さらに多くを求めさせます",
            des5: "最近、ブラック神話：悟空は海外でオフラインの試遊体験イベントを開催し、外国のメディアGamerantがそのレビュー体験を共有しました。 数時間の試遊体験の後、筆者はブラック神話が「本物の取引」であり、以前の多くのデモと同様に優れたゲームであり、注目に値するゲームであると述べました。",
            backToHome: "ホームに戻る",
            new1des1:
                "8月20日の発売日が近づくにつれて、ブラック神話：悟空は国内外のプレイヤーから注目を集め、常にSteamのウィッシュリストコレクションランキングで1位にランクインしています。 ただし、Steamバックエンドには具体的なコレクション数が表示されません。",
            new1des2: `市場調査サイトGame World Observerによると、夏のゲームフェスティバルでブラック神話：悟空の最新の予告編が公開された後、ゲームのSteamウィッシュリスト数が約854,000件増加し、6月7日から13日までの他の展示されたゲームよりも多くなりました。 レポートによると、ブラック神話：悟空のウィッシュリスト数は既に400万人を超えており、そのうち60％〜70％が中国のユーザーであると推定されています。`,
            new1des3:
                "他のゲームの場合、文明7は593,000のウィッシュリストを追加し、セブンデイズは281,000のウィッシュリストを追加し、ドラゴンボールライジングゼロは182,000のウィッシュリストを追加し、デストロイ：ダークエイジは138,000のウィッシュリストを追加し、モンスターハンターライズは129,000のウィッシュリストを追加しました。",
            new1des4: `調査サイトは、ファンの数を一定の倍数でかけてSteamのウィッシュリスト数を推定します。 通常、ファン数の7〜20倍（中央値は12倍）です。 したがって、これは正確ではなく、参考用です。`,
            new2des1: `今月初め、Game Scienceは、XSX|Sプラットフォームでのブラック神話：悟空のリリースが遅れると発表しました。 このニュースが出ると、広範な関心と議論を呼びました。 多くの人々は、遅延の理由がXbox Series Sコンソールの性能が不十分であり、開発チームが予定された時間内に最適化作業を完了できなかったためだと推測しました。`,
            new2des2: `外部のさまざまな推測に直面して、Xboxは最近声明を発表して、この問題を明確にしようとしました。`,
            new2des3: `声明では、Xboxは「ブラック神話：悟空がXSX|Sでリリースされることを期待しており、この期待されるゲームを私たちのプラットフォームにもたらすためにGame Scienceと協力しています。 私たちは私たちのパートナーが他のプラットフォーム所有者と達成した契約について意見を述べることはできませんが、引き続きXboxを最高のプラットフォームにするために集中し、優れたゲームはその核心です。」`,
            new2des4: `声明は穏やかに見えますが、慎重なプレイヤーはその中にヒントを見つけることができます。 一部のプレイヤーは、Xboxが声明で言及した「他のプラットフォーム所有者と達成した契約」が実際にはソニーがGame Scienceとどのような形で独占契約を結んだかを意味すると考えています。 この意見はソーシャルメディアで急速に議論されました。 1人のユーザーは「だから@JezCordenが正しい。 ソニーは本当に独占契約を結んだ。」とコメントし、別のユーザーは「プレイステーションは再び限られた時間の独占権を支払った。 Xboxがこれをやれば間違いなく厳しく非難されるだろう。」と述べました。`,
            new2des5: `同時に、一部のプレイヤーはXboxの声明が責任を回避しようとしていると不満を表明し、自分の問題を競合他社に押し付けようとしていると考えています。 1人のユーザーは皮肉っぽく言いました。 「マイクロソフトが問題をソニーに押し付けようとしているのを見てください。 ㅋㅋㅋ。」`,
            new2des6: `「ブラック神話：悟空」は8月20日に公式にリリースされ、PCおよびPS5プラットフォームで提供される予定です。 「ブラック神話：悟空」XSX|Sバージョンの具体的なリリース日はまだ明確ではありません。 時間が経つにつれて、Game ScienceとXboxがプレイヤーにより多くの良いニュースをもたらすことを願っています。`,
            new3des1: `最近、インターネットで「ブラック神話：悟空」のグラフィックカードが発見されました。 これらのカスタマイズされたグラフィックカードの画像は、ユーザーhongxing2020によってX/Twitterに投稿されました。`,
            new3des2: `他のMSI協力製品とは異なり、このグラフィックカードには多くの個性化されたモデルがなく、主人公「孫悟空」のイメージが裏パネルに印刷されています。 これは公式製品ではなく、ファン製品である可能性があります。`,
            new3des3: `テクニカルサイトVideocardzが最初の投稿者に「カスタムか新しいモデルか」と尋ねたとき、ユーザーhongxing2020は「おそらく...」と答え、これは推測をさらに増幅させました。`,
            new3des4: `「ブラック神話：悟空」は2024年8月20日にリリースされる予定であり、数週間以内に確かなニュースを期待しています。`,
            new4des1: `今月初め、Game Scienceは、XSX|Sバージョンの「ブラック神話：悟空」がプラットフォームでの最適化問題により遅れると確認しました。 IGNによると、Xboxの公式は今日、この問題に対して回答しました。`,
            new4des2: `「ブラック神話：悟空がXSX|Sでリリースされることを期待しており、この期待されるゲームを私たちのプラットフォームにもたらすためにGame Scienceと協力しています。 私たちは私たちのパートナーが他のプラットフォーム所有者と達成した契約について意見を述べることはできませんが、引き続きXboxを最高のプラットフォームにするために集中し、優れたゲームはその核心です。」`,
            new4des3: `また、IGNは、Game Scienceが既にソニーと独占契約を結んでいるという噂を言及し、マイクロソフトがこの声明でそれを示唆している可能性があると考えています。`,
            new5des1: `最近、ブラック神話：悟空は海外でオフラインの試遊体験イベントを開催し、外国のメディアGamerantがそのレビュー体験を共有しました。 数時間の試遊体験の後、筆者はブラック神話が「本物の取引」であり、以前の多くのデモと同様に優れたゲームであり、注目に値するゲームであると述べました。`,
            new5des2: `記事は、ブラック神話が従来のソウルズゲームではないことを指摘しています。 このゲームの戦闘速度は速く激しく、従来のブロックと防御がなく、避けることが重要です。 また、ソウルズゲームの世界はより開かれており、ブラック神話の最初の数時間はかなり直線的に見えます。 キャラクターの成長の面では、ブラック神話は標準的なスキルツリーと獲得した能力を通じて向上し、ポイントを使用して力とスタミナなどの属性を強化しません。`,
            new5des3: `コンボ技術は敵に大きなダメージを与えることができ、混合充電攻撃はプレイヤーが自分の技術をさまざまな方法で表現できるようにします。 プレイヤーはダメージを与えるために冒険しますが、必要に応じて避けることが重要です。 選択するプロセスは魅力的な体験です。 そして、呪文と変身が追加されると、ゲームはさらに楽しくなります。`,
            new5des4: `筆者は、Game Scienceが「西遊記」を成功裏に適応し、ユニークで忘れられない敵を作り出したと指摘しています。 筆者は3時間で少なくとも5人のボスに会い、攻撃的なものも防御的なものもありますが、ほとんどは攻撃的であり、防御的です。 ボスを倒すと特別な変身能力を得ることができ、これはウォンコンの進化に重要です。`,
            new5des5: `最後に筆者は、体験コンテンツの最高の報酬は「もっと欲しい」ということであり、ブラック神話がそれを達成したと述べています。 彼は「もっと記憶に残る世界を見たいし、技術を向上させ、もっと多くのボスに挑戦し、戦闘の進化を体験したい」と書き、ブラック神話は実際の取引であり、広告ではないと述べています。 これは2024年下半期に最も期待されるゲームの1つです。`,
            source: "出典",
            edit: "編集",
            EXCHANGE: "交換",
            OVERVIEW: "ストーリー",
            o1des1: `西遊記の後、妖魔たちは互いに戦っています。 以前の世代の「天道」と魏空が率いる妖魔連盟は、仏教と道教の宗派を成功裏に打ち負かし、世界の新しい支配階級となりました。 法教末期が来て、ライライが復活し、道教が隆盛します。 しかし、魏空は少しずつ奇妙なことを発見しており、彼は利用されているように見え、世界がより良くなる代わりに悪くなっていることに気づき始めました。 そこで、彼は自責と罪悪感に満ち、`,
            o1des2: `世界を救う新しい方法を探し始めます。`,
            TOKEPMIC: "TOKENMIC",
            t1des1: `インセンティブ`,
            t1des2: `Cexリスト`,
            t1des3: `公正な発行`,
            PROGRAM: "プログラム",
            p1des1: `「ブラック神話：悟空」ゲームをクリアすることで、`,
            p1des2: `実績システムのプロモーションに役立ちます。`,
            p1des3: `実績システムのバッジを無料で入手できます。`,
            p1des4: `実績の難易度に応じて階層化された無料報酬を発行します。`,
            p1des5: `すべての実績を達成すると、追加のインセンティブがもらえます。`,
            returnInfo: `< 情報ホームに戻る`,
            gameInfo: `ゲーム情報`,
            TOKENOMICS: `トークンノミクス`,
            e1des: `ゲームコードを引き換えてください`,
            enter: `換算`,
            congratulations: `おめでとうございます！ 交換に成功しました`,
            s1des1: `Steamプラットフォームに移動して、ゲームをアクティブ化してください。 アクティベーションコードを詐欺師に送らないでください（当社はユーザーの誤りによる損失に対して責任を負いません）`,
            ok: "確認",
            userPrivacy: `ユーザー契約と個人情報保護方針`,
            userPdes1: `ゲームブラック神話：悟空をサポートし、注目していただきありがとうございます！ $wukongは、このゲームをコミュニティ努力を通じて宣伝し、プロモーションし、ゲーム内活動を報酬するのに重点を置いています。 続行する前に、次の声明を注意深くお読みください。`,
            userPdes2: `コミュニティトークンの性質：このトークンはブラック神話：悟空のファンコミュニティによって自発的に発行されたものであり、公式ゲーム開発者によって発行されたものではありません。 これはゲームをコミュニティ努力を通じて宣伝し、プロモーションし、ゲーム内活動を報酬するのに重点を置いています。 実際の通貨価値はありません。`,
            userPdes3: `非公式トークン：このトークンはブラック神話：悟空の開発者や公式チームとは関係ありません。 公式ゲーム開発者はこのトークンの発行、使用、または関連活動について責任を負いません。`,
            userPdes4: `報酬の性質：このトークンはゲーム内活動を報酬するためのものです。 このトークンを保有または使用するプレイヤーは、コミュニティ内で特別な報酬や名誉を受け取ることができますが、どの形式の報酬や利益も保証しません。`,
            userPdes5: `投資ツールではない：このトークンは投資ツールではなく、どの形式の報酬や利益も保証しません。 このトークンを購入または保有することは完全に自発的であり、ブラック神話：悟空に対する支持と愛に基づいています。`,
            userPdes6: `リスク警告：暗号通貨市場は非常に変動的であり、このトークンを購入または保有することは一定のリスクがあるかもしれません。 慎重に行動し、これらのリスクを十分に理解し、受け入れてください。`,
            userPdes7: `上記の声明に同意し、受け入れる場合は、以下の「続行」ボタンをクリックしてください。`,
            userPdes8: `同意し、受け入れます。`,
            userPdes9: `続行`,
            COPY: "コピー",
            invalidCode: `無効な製品コード`,
            invalidCodeDes: `入力した製品コードが無効です`,
            invalidCodeDes2: `入力したコードが正しいか確認してください`,
            tryAgain: `再試行`,
        },
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
