import React from "react";
import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import Top1 from "@/components/Info/assets/top1.png";
import { useNavigate } from "react-router-dom";
import News1Img from "@/components/Info/assets/news1.png";
import News2Img from "@/components/Info/assets/news2.png";
import News3Img from "@/components/Info/assets/news3.png";
import News4Img from "@/components/Info/assets/news4.png";
import News5Img from "@/components/Info/assets/news5.png";
import { useTranslation } from "react-i18next";

export const news = [
    {
        id: 1,
        img: News1Img,
        title: "title1",
        des: "des1",
        time: "2024-06-24  5:27:12 PM",
        source: "$WUKONG",
        edit: "$WUKONG",
    },
    {
        id: 2,
        img: News2Img,
        title: "title2",
        des: "des2",
        time: "2024-06-24  5:27:12 PM",
        source: "$WUKONG",
        edit: "$WUKONG",
    },
    {
        id: 3,
        img: News3Img,
        title: "title3",
        des: "des3",
        time: "2024-06-24  5:27:12 PM",
        source: "$WUKONG",
        edit: "$WUKONG",
    },
    {
        id: 4,
        img: News4Img,
        title: "title4",
        des: "des4",
        time: "2024-06-24  5:27:12 PM",
        source: "$WUKONG",
        edit: "$WUKONG",
    },
    {
        id: 5,
        img: News5Img,
        title: "title5",
        des: "des5",
        time: "2024-06-24  5:27:12 PM",
        source: "$WUKONG",
        edit: "$WUKONG",
    },
];

const MItem = ({ item }: { item: any }) => {
    const { t, i18n } = useTranslation();
    return (
        <Box
            sx={{
                borderBottom: "1px solid #FFF",
                paddingBottom: "10px",
                marginTop: "25px",
            }}
        >
            <Text
                sx={{
                    fontSize: "15px",
                }}
            >
                {t(item.title)}
            </Text>
            <Image
                src={item.img}
                sx={{
                    width: "100%",
                    marginTop: "10px",
                }}
            ></Image>
            <Text
                sx={{
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    color: "#AAA",
                    marginTop: "10px",
                }}
            >
                {t(item.des)}
            </Text>
            <Flex
                sx={{
                    gap: "10px",
                    color: "#AAA",
                    fontSize: "7px",
                    fontWeight: 400,
                    marginTop: "20px",
                }}
            >
                {/* <Text>{item.time}</Text> */}
                <Text>
                    {t("source")}: {item.source}
                </Text>
                <Text>
                    {t("edit")}: {item.edit}
                </Text>
            </Flex>
        </Box>
    );
};

const InfoPage = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <Box
            sx={{
                fontFamily:
                    i18n.language === "tw" ? "HanYiKaiTiFan" : "CrimsonPro",
                padding: isPc ? "0 20px 180px" : "80px 20px 80px",
            }}
        >
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <Text
                    onClick={() => {
                        navigate("/");
                    }}
                    sx={{
                        position: "absolute",
                        bottom: "18px",
                        left: "0%",
                        cursor: "pointer",
                        fontSize: "1.5625vw",
                    }}
                >
                    {"< "}
                    {t("backToHome")}
                </Text>
                <Image
                    src={Top1}
                    sx={{
                        width: "100%",
                    }}
                ></Image>
            </Box>
            <Box sx={{}}>
                {news.map((item, index) => {
                    return (
                        <Box
                            key={index}
                            onClick={() => {
                                navigate(`/infoDetail/${item.id}`);
                            }}
                        >
                            {isPc ? (
                                <Box
                                    sx={{
                                        borderBottom: "1px solid #FFF",
                                        paddingBottom: "20px",
                                        marginTop: "26px",
                                    }}
                                >
                                    <Text
                                        sx={{
                                            fontSize: "1.0417vw",
                                        }}
                                    >
                                        {t(item.title)}
                                    </Text>
                                    <Flex
                                        sx={{
                                            marginTop: "20px",
                                            gap: "12px",
                                        }}
                                    >
                                        <Image
                                            src={item.img}
                                            sx={{
                                                width: "338px",
                                            }}
                                        ></Image>
                                        <Flex
                                            sx={{}}
                                            flexDir={"column"}
                                            justify={"space-between"}
                                        >
                                            <Text
                                                sx={{
                                                    fontSize: "0.9375vw",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    color: "#AAA",
                                                }}
                                            >
                                                {t(item.des)}
                                            </Text>
                                            <Flex
                                                sx={{
                                                    gap: "40px",
                                                    color: "#AAA",
                                                    fontSize: "0.9375vw",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {/* <Text>{item.time}</Text> */}
                                                <Text>
                                                    {t("source")}: {item.source}
                                                </Text>
                                                <Text>
                                                    {t("edit")}: {item.edit}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Box>
                            ) : (
                                <MItem item={item}></MItem>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default InfoPage;
