import { Box, Flex, Text, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useRef } from "react";
import kong3Mp4 from "@/components/Home/assets/kong3.mp4";
import O2Img from "@/components/Home/assets/o2.png";
import { motion, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

const Program = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const ref = useRef(null);
    const isInView = useInView(ref);
    const { t, i18n } = useTranslation();

    return (
        <Box ref={ref}>
            <video
                autoPlay
                loop
                muted
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    objectFit: "cover",
                }}
            >
                <source src={kong3Mp4} type="video/mp4" />
            </video>
            <motion.div
                animate={{
                    y: isInView ? 140 : 0,
                    opacity: isInView ? 1 : 0.5,
                }}
                transition={{
                    duration: 0.5,
                }}
                style={{
                    position: "absolute",
                    top: "0px",
                    right: "200px",
                    width: "580px",
                    textAlign: "left",
                    zIndex: 1000,
                }}
            >
                <Text
                    sx={{
                        fontSize: "2.3438vw",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    {t("PROGRAM")}
                </Text>
                <Box
                    sx={{
                        lineHeight: "34px",
                        fontSize: "1.0417vw",
                    }}
                >
                    <Text
                        sx={{
                            marginTop: "58px",
                        }}
                    >
                        {t("p1des1")}
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            {t("p1des2")}
                        </span>
                    </Text>
                    <Text>{t("p1des3")}</Text>
                    <Text>
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            {t("p1des4")}
                        </span>
                    </Text>
                    <Text>{t("p1des5")}</Text>
                </Box>
            </motion.div>{" "}
            <Image
                src={O2Img}
                sx={{
                    position: "absolute",
                    left: "0%",
                    top: "0%",
                    width: "80%",
                }}
            ></Image>
        </Box>
    );
};

export default Program;
