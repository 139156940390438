import { Box, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import React, { ReactElement, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrivacyModal from "./components/PrivacyModal";
import Header from "./components/Header";

const App = ({ children }: React.PropsWithChildren<unknown>): ReactElement => {
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const privacy = localStorage.getItem("privacy");
        if (privacy !== "true") {
            onOpen();
        }

        const language = localStorage.getItem("language");
        if (language === "zh") {
            i18n.changeLanguage("zh");
        } else if (language === "tw") {
            i18n.changeLanguage("tw");
        } else if (language === "ko") {
            i18n.changeLanguage("ko");
        } else if (language === "ja") {
            i18n.changeLanguage("ja");
        } else {
            i18n.changeLanguage("en");
        }
    }, []);
    return (
        // TO-DO: use color mode when implementing light/dark
        <Box
            height="100%"
            color="white"
            minH={"100vh"}
            sx={{
                fontFamily: i18n.language === "tw" ? "HYSunShangXiangF" : "z",
                position: "relative",
            }}
        >
            {!isPc && <Header></Header>}
            {children}
            <Outlet />
            <PrivacyModal isOpen={isOpen} onClose={onClose}></PrivacyModal>
        </Box>
    );
};

export default App;
