import React, { useRef } from "react";
import { Box, Flex, Text, Image, useMediaQuery } from "@chakra-ui/react";
import kong1Mp4 from "@/components/Home/assets/kong1.mp4";
import InfoBg from "@/assets/infoBg.png";
import DiscordIcon from "@/components/Home/assets/discord.png";
import TwIcon from "@/components/Home/assets/tw.png";
import TgIcon from "@/components/Home/assets/tg.png";
import WUKONG from "@/components/Home/assets/WUKONG.png";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

const Home = () => {
    const ref = useRef(null);
    const isInView = useInView(ref);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");

    return (
        <Box ref={ref}>
            <video
                autoPlay
                loop
                muted
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    objectFit: "cover",
                }}
            >
                <source src={kong1Mp4} type="video/mp4" />
            </video>
            <Box
                sx={{
                    width: "11.6146vw",
                    height: "5.9896vw",
                    cursor: "pointer",
                    background: `url(${InfoBg}) no-repeat`,
                    backgroundSize: "100% 100%",
                    position: "absolute",
                    right: "0",
                    top: "5.2083vw",
                    fontFamily:
                        i18n.language === "tw" ? "HanYiKaiTiFan" : "CrimsonPro",
                    fontSize: "1.0417vw",
                    color: "#000",
                    paddingTop: "3.3333vw",
                }}
                onClick={() => {
                    navigate("/info");
                }}
            >
                {t("gameInfo")}
            </Box>
            <motion.div
                animate={{ y: isInView ? -64 : 0, opacity: isInView ? 1 : 0 }}
                transition={{
                    duration: 0.5,
                }}
                style={{
                    position: "absolute",
                    left: "80px",
                    bottom: "0",
                }}
            >
                <Flex
                    align={"center"}
                    sx={{
                        gap: "24px",
                        "& img": {
                            width: "24px",
                        },
                    }}
                >
                    <Image src={DiscordIcon}></Image>
                    <Image src={TwIcon}></Image>
                    <Image src={TgIcon}></Image>
                </Flex>
            </motion.div>

            <motion.div
                animate={{ y: isInView ? 250 : 0, opacity: isInView ? 1 : 0 }}
                transition={{
                    duration: 0.5,
                }}
                style={{
                    position: "absolute",
                    right: "250px",
                    top: "0",
                }}
            >
                <Image
                    src={WUKONG}
                    sx={{
                        width: "26.0417vw",
                    }}
                ></Image>
            </motion.div>
        </Box>
    );
};

export default Home;
