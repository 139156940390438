import { Box, Image } from "@chakra-ui/react";
import React from "react";
import InfoPage from "@/components/Info";

const Info = () => {
    return (
        <Box
            sx={{
                maxWidth: "1000px",
                width: "100%",
                margin: "0 auto",
            }}
        >
            <InfoPage></InfoPage>
        </Box>
    );
};

export default Info;
