import {
    Modal,
    Text,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Flex,
    Image,
    Box,
    useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import BtBg from "@/assets/pbg.png";
import YesIcon from "@/assets/yes.png";
import CloseIcon from "@/assets/close.png";
import { useTranslation } from "react-i18next";
import { menus } from "./NavBar";
import InfoBg from "@/assets/infoBg.png";
import { useLocation, useNavigate } from "react-router-dom";
import DiscordIcon from "@/components/Home/assets/discord.png";
import TwIcon from "@/components/Home/assets/tw.png";
import TgIcon from "@/components/Home/assets/tg.png";
const MenuModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");
    const location = useLocation();
    return (
        <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
            <ModalOverlay
                sx={{
                    background: "rgba(0, 0, 0, 0)",
                    backdropFilter: "blur(10px)",
                }}
            />
            <ModalContent
                sx={{
                    padding: "20px",
                    maxWidth: "720px",
                    background: "rgba(0, 0, 0, 0.10)",
                    backdropFilter: "blur(10px)",
                    height: "100%",
                    margin: "0",
                }}
            >
                <ModalBody
                    sx={{
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    <Image
                        onClick={onClose}
                        src={CloseIcon}
                        sx={{
                            position: "absolute",
                            right: "20px",
                            top: "20px",
                            width: "24px",
                            height: "24px",
                        }}
                    ></Image>
                    <Box
                        sx={{
                            marginTop: "40px",
                        }}
                    >
                        {menus.map((item, index) => {
                            return (
                                <Flex
                                    onClick={() => {
                                        if (location.pathname != "/") {
                                            navigate("/");
                                        }

                                        setTimeout(() => {
                                            const ele = document.getElementById(
                                                item.name,
                                            );
                                            ele.scrollIntoView({
                                                behavior: "smooth",
                                                block: "start",
                                            });
                                            onClose();
                                        }, 100);
                                    }}
                                    align={"center"}
                                    key={index}
                                    sx={{
                                        cursor: "pointer",
                                        marginBottom: "30px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "10px",
                                            height: "10px",
                                            transform: "rotate(45deg)",
                                            backgroundColor: "#fff",
                                            marginRight: "12px",
                                        }}
                                    ></Box>
                                    <Text
                                        sx={{
                                            fontSize: "15px",
                                        }}
                                    >
                                        {t(item.name)}
                                    </Text>
                                </Flex>
                            );
                        })}
                        <Box
                            sx={{
                                width: "190px",
                                height: "120px",
                                cursor: "pointer",
                                background: `url(${InfoBg}) no-repeat`,
                                backgroundSize: "100% 100%",
                                fontFamily:
                                    i18n.language === "tw"
                                        ? "HanYiKaiTiFan"
                                        : "CrimsonPro",
                                fontSize: "15px",
                                color: "#000",
                                paddingTop: "74px",
                                textAlign: "center",
                                margin: "auto",
                            }}
                            onClick={() => {
                                navigate("/info");
                                onClose();
                            }}
                        >
                            {t("gameInfo")}
                        </Box>
                        <Flex
                            align={"center"}
                            sx={{
                                width: "100%",
                                gap: "50px",
                                marginTop: "10%",
                                "& img": {
                                    width: "24px",
                                },
                            }}
                            justify={"center"}
                        >
                            <Image src={DiscordIcon}></Image>
                            <Image src={TwIcon}></Image>
                            <Image src={TgIcon}></Image>
                        </Flex>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
export default MenuModal;
