import {
    Modal,
    Text,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Flex,
    useMediaQuery,
} from "@chakra-ui/react";
import BtBg from "@/components/Home/assets/modal-bt.png";
import { useTranslation } from "react-i18next";
const SuccessModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered autoFocus={false}>
            <ModalOverlay
                sx={{
                    background: "rgba(217, 217, 217, 0.45)",
                    backdropFilter: "blur(10px)",
                }}
            />
            <ModalContent
                sx={{
                    background: "rgba(0, 0, 0, 0.75)",
                    backdropFilter: "blur(10px)",
                    maxWidth: "720px",
                    padding: isPc ? "20px" : "20px 0px 20px",
                    margin: "0 20px",
                }}
            >
                <ModalBody
                    sx={{
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    <Text
                        sx={{
                            fontSize: isPc ? "30px" : "15px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                        }}
                    >
                        {t("invalidCode")}
                    </Text>
                    <Text
                        sx={{
                            color: "#868686",
                            fontSize: isPc ? "20px" : "15px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            marginTop: "13px",
                            lineHeight: "normal",
                        }}
                    >
                        {t("invalidCodeDes")}
                    </Text>
                    <Text
                        sx={{
                            fontSize: isPc ? "20px" : "15px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            marginTop: "27px",
                        }}
                    >
                        {t("invalidCodeDes2")}
                    </Text>
                    <Flex
                        sx={{
                            padding: isPc ? "10px" : "6px",
                            border: "1px solid #FFF",
                            marginTop: isPc ? "70px" : "10px",
                            cursor: "pointer",
                            background: "#000",
                        }}
                    >
                        <Flex
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: `url(${BtBg})`,
                                backgroundSize: "100% 100%",
                                fontSize: isPc ? "30px" : "15px",
                                cursor: "pointer",
                                padding: "6px",
                            }}
                            justify={"center"}
                            align={"center"}
                        >
                            {t("tryAgain")}
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
export default SuccessModal;
