import { Box, Flex, Text, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useRef } from "react";
import kong3Mp4 from "@/components/Home/assets/kong33.mp4";
import O2Img from "@/components/Home/assets/o2.png";
import { motion, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

const Program = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const ref = useRef(null);
    const isInView = useInView(ref);
    const { t, i18n } = useTranslation();

    return (
        <Box
            ref={ref}
            sx={{
                // height: "100vh",
                position: "relative",
                marginTop: "60px",
            }}
        >
            <Box
                sx={{
                    // position: "absolute",
                    // left: "50%",
                    // top: "44%",
                    width: "90%",
                    textAlign: "left",
                    margin: "0 auto",
                    // transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                    opacity: isInView ? 1 : 0,
                    transition: "opacity 3s",
                    background: `url(${O2Img}) no-repeat `,
                    // backgroundSize: "contain",
                    backgroundPosition: "0% 0%",
                    backgroundSize: "100%",
                    paddingTop: "100px",
                }}
            >
                <Text
                    sx={{
                        fontSize: "15px",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    {t("PROGRAM")}
                </Text>
                <Box
                    sx={{
                        fontSize: "12px",
                    }}
                >
                    <Text
                        sx={{
                            marginTop: "12px",
                        }}
                    >
                        {t("p1des1")}
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            {t("p1des2")}
                        </span>
                    </Text>
                    <Text
                        sx={{
                            marginTop: "4px",
                        }}
                    >
                        {t("p1des3")}
                    </Text>
                    <Text
                        sx={{
                            marginTop: "4px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            {t("p1des4")}
                        </span>
                    </Text>
                    <Text
                        sx={{
                            marginTop: "4px",
                        }}
                    >
                        {t("p1des5")}
                    </Text>
                </Box>
                <video
                    autoPlay
                    loop
                    muted
                    style={{
                        width: "100%",
                        objectFit: "cover",
                        // position: "absolute",
                        // top: "60%",
                        // left: "50%",
                        // transform: "translate(-50%, -50%)",
                    }}
                >
                    <source src={kong3Mp4} type="video/mp4" />
                </video>
            </Box>
        </Box>
    );
};

export default Program;
