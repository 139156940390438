import {
    Box,
    Container,
    Image as CharkraImage,
    Flex,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Mousewheel } from "swiper";
import "swiper/css/bundle";
import NavBar from "@/components/NavBar";
import Bg3 from "@/components/Home/assets/bg3.png";
import Bg4 from "@/components/Home/assets/bg4.png";

import Exchange from "@/components/Home/Exchange";
import Overview from "@/components/Home/Overview";
import Program from "@/components/Home/Program";
import Tokenpmic from "@/components/Home/Tokenpmic";
import HomeComp from "@/components/Home/Home";
import Language from "@/components/Home/Language";

import MHomeComp from "@/components/Home/MHomeComp";
import MExchange from "@/components/Home/MExchange";
import MOverview from "@/components/Home/MOverview";
import MProgram from "@/components/Home/MProgram";
import MTokenpmic from "@/components/Home/MTokenpmic";

const MHome = () => {
    return (
        <Box
            sx={{
                background: "#000000",
            }}
        >
            <Box id="HOME">
                <MHomeComp></MHomeComp>
            </Box>
            <Box id="EXCHANGE">
                <MExchange></MExchange>
            </Box>
            <Box id="OVERVIEW">
                <MOverview></MOverview>
            </Box>

            <Box id="PROGRAM">
                <MProgram></MProgram>
            </Box>
            <Box id="TOKENOMICS">
                <MTokenpmic></MTokenpmic>
            </Box>
        </Box>
    );
};

const Home = (): ReactElement => {
    const [isPc] = useMediaQuery("(min-width: 800px)");
    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleActiveIndex = (index: number) => {
        setActiveIndex(index);
        swiper.slideTo(index);
    };

    return isPc ? (
        <Box
            sx={{
                height: "100vh",
                "::-webkit-scrollbar": {
                    width: "8px",
                },
                // height: "100%",
                ".swiper-wrapper": {
                    width: "100%",
                    position: "relative",
                    height: "100%",
                },
            }}
        >
            <NavBar
                activeIndex={activeIndex}
                handleActiveIndex={handleActiveIndex}
            ></NavBar>
            <Language></Language>
            <Swiper
                direction="vertical"
                mousewheel={true}
                style={{
                    width: "100%",
                    position: "relative",
                    height: "100%",
                }}
                spaceBetween={50}
                modules={[Mousewheel]}
                onSlideChange={(swiper) => {
                    console.log(swiper.activeIndex, "swiper.activeIndex");
                    setActiveIndex(swiper.activeIndex);
                }}
                onSwiper={(swiper) => {
                    setSwiper(swiper);
                }}
            >
                <SwiperSlide
                    style={{
                        color: "#fff",
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        position: "relative",
                    }}
                >
                    <HomeComp></HomeComp>
                </SwiperSlide>
                <SwiperSlide
                    style={{
                        color: "#fff",
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        background: `url(${Bg4}) no-repeat `,
                        backgroundSize: "100% auto",
                        backgroundPosition: "center",
                    }}
                >
                    <Exchange></Exchange>
                </SwiperSlide>
                <SwiperSlide
                    style={{
                        color: "#fff",
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        background: `#000`,
                        backgroundSize: "cover",
                    }}
                >
                    <Overview></Overview>
                </SwiperSlide>
                <SwiperSlide
                    style={{
                        color: "#fff",
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        background: `#000`,
                        backgroundSize: "cover",
                    }}
                >
                    <Program></Program>
                </SwiperSlide>
                <SwiperSlide
                    style={{
                        color: "#fff",
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        background: `url(${Bg3}) no-repeat `,
                        backgroundSize: "cover",
                    }}
                >
                    <Tokenpmic></Tokenpmic>
                </SwiperSlide>
            </Swiper>
        </Box>
    ) : (
        <MHome></MHome>
    );
};

export default Home;
