import React, { useRef } from "react";
import {
    Box,
    Flex,
    Text,
    Image,
    useClipboard,
    useMediaQuery,
} from "@chakra-ui/react";
import { motion, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
import CopyIcon from "@/components/Home/assets/copy1.png";
import Bg3 from "@/components/Home/assets/bg33.png";
const Tokenpmic = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const ref = useRef(null);
    const isInView = useInView(ref);
    const { t, i18n } = useTranslation();

    const { onCopy } = useClipboard("0xxxxxxXXXXXXXXXXXXXXXXXXXXXXXXX");

    return (
        <Box
            ref={ref}
            sx={{
                position: "relative",
                marginTop: "40px",
                paddingBottom: "100px",
            }}
        >
            <Box
                sx={{
                    // position: "absolute",
                    // top: "50%",
                    // left: "50%",
                    width: "90%",
                    textAlign: "left",
                    margin: "0 auto",
                    // transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                    opacity: isInView ? 1 : 0,
                    transition: "opacity 3s",
                    background: `url(${Bg3}) no-repeat `,
                    backgroundPosition: "50% 50%",
                    backgroundSize: "100%",
                    paddingTop: "50px",
                }}
            >
                <Text
                    sx={{
                        fontSize: "15px",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    {t("TOKENPMIC")}
                </Text>
                <Box
                    sx={{
                        lineHeight: "2.0833vw",
                        fontSize: "15px",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    <Text
                        sx={{
                            marginTop: "26px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            50%{" "}
                        </span>{" "}
                        {t("t1des1")}
                    </Text>
                    <Text
                        sx={{
                            marginTop: "18px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            20%{" "}
                        </span>{" "}
                        {t("t1des3")}
                    </Text>
                    <Text
                        sx={{
                            marginTop: "18px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            20%{" "}
                        </span>{" "}
                        LP{" "}
                    </Text>

                    <Text
                        sx={{
                            marginTop: "18px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            10%{" "}
                        </span>{" "}
                        {t("t1des2")}
                    </Text>

                    <Flex
                        sx={{
                            fontFamily:
                                i18n.language === "tw"
                                    ? "HanYiKaiTiFan"
                                    : "CrimsonPro",
                            fontSize: "12px",
                            fontWeight: 900,
                            cursor: "pointer",
                            marginTop: "30px",
                        }}
                        align={"center"}
                        onClick={onCopy}
                    >
                        <Text sx={{ marginRight: "10px" }}>
                            CA：0xxxxxxXXXXXXXXXXXXXXXXXXXXXXXXX
                        </Text>
                        <Image
                            src={CopyIcon}
                            sx={{
                                width: "12px",
                                height: "12px",
                            }}
                        ></Image>
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
};

export default Tokenpmic;
