import { Box, Text, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import News1 from "./News1";
import { news } from "./index";
import News2 from "./News2";
import News3 from "./News3";
import News4 from "./News4";
import News5 from "./News5";
import { useTranslation } from "react-i18next";

const InfoDetail = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const { id } = useParams();
    const newItem = news.find((item) => Number(item.id) === Number(id));

    return (
        <Box
            sx={{
                maxWidth: "1000px",
                margin: "0 auto",
                fontFamily:
                    i18n.language === "tw" ? "HanYiKaiTiFan" : "CrimsonPro",
                padding: isPc ? "150px 20px 80px" : "40px 20px 80px",
                lineHeight: "normal",
            }}
        >
            {isPc && (
                <Text
                    sx={{
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                        fontSize: "1.5625vw",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        navigate("/info");
                    }}
                >
                    {t("returnInfo")}
                </Text>
            )}
            <Box
                sx={{
                    marginTop: "34px",
                }}
            >
                <Flex
                    sx={{
                        gap: isPc ? "20px" : "10px",
                        fontSize: isPc ? "16px" : "10px",
                    }}
                >
                    {/* <Text>{newItem.time}</Text> */}
                    <Text>
                        {t("source")}: {newItem.source}
                    </Text>
                    <Text>
                        {t("edit")}: {newItem.edit}
                    </Text>
                </Flex>
                <Box
                    sx={{
                        position: "relative",
                        marginTop: "10px",
                    }}
                >
                    <Image
                        src={newItem.img}
                        sx={{
                            width: "100%",
                        }}
                    ></Image>
                    <Text
                        sx={{
                            position: "absolute",
                            bottom: "20px",
                            left: "0",
                            width: "100%",
                            padding: "0 20px",
                            fontFamily:
                                i18n.language === "tw"
                                    ? "HanYiKaiTiFan"
                                    : "CrimsonPro",
                            fontSize: isPc ? "1.5625vw" : "13px",
                            fontWeight: 700,
                        }}
                    >
                        {t(newItem.title)}
                    </Text>
                </Box>
            </Box>
            {newItem && (
                <Box
                    sx={{
                        background: "#fff",
                        padding: isPc ? "30px 64px" : "20px 20px",
                        color: "#000",
                    }}
                >
                    {newItem.id === 1 && <News1></News1>}
                    {newItem.id === 2 && <News2></News2>}
                    {newItem.id === 3 && <News3></News3>}
                    {newItem.id === 4 && <News4></News4>}
                    {newItem.id === 5 && <News5></News5>}
                </Box>
            )}
        </Box>
    );
};

export default InfoDetail;
