import React, { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import Info from "./pages/Info";
import InfoDetail from "./components/Info/InfoDetail";

const AppRoutes = (): ReactElement => {
    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<Home />} />{" "}
                <Route path="/info" element={<Info />} />
                <Route path="/infoDetail/:id" element={<InfoDetail />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
