import {
    Box,
    Image,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    useMediaQuery,
} from "@chakra-ui/react";
import LanguageIcon from "@/components/Home/assets/language.png";
import { useTranslation } from "react-i18next";

export const list = [
    {
        name: "简体中文",
        value: "zh",
    },
    {
        name: "繁体中文",
        value: "tw",
    },
    {
        name: "English",
        value: "en",
    },
    {
        name: "日本语",
        value: "ja",
    },
    {
        name: "한국인",
        value: "ko",
    },
];

const Language = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const { i18n } = useTranslation();

    const changeLanguage = (language: string) => {
        console.log(language);
        i18n.changeLanguage(language);
        localStorage.setItem("language", language);
    };
    return (
        <Box
            sx={{
                position: "fixed",
                right: "80px",
                bottom: "64px",
                zIndex: 1000,
            }}
        >
            <Popover trigger="hover" placement="top">
                <PopoverTrigger>
                    <Image
                        src={LanguageIcon}
                        sx={{
                            width: "2.6042vw",
                            height: "2.6042vw",
                        }}
                    ></Image>
                </PopoverTrigger>
                <PopoverContent
                    sx={{
                        background: "transparent",
                        border: "none",
                        width: "fit-content",
                    }}
                >
                    <PopoverBody>
                        {list.map((item) => {
                            return (
                                <Box
                                    onClick={() => {
                                        changeLanguage(item.value);
                                    }}
                                    sx={{
                                        fontSize: "1.0417vw",
                                        fontFamily:
                                            i18n.language === "tw"
                                                ? "HanYiKaiTiFan"
                                                : "CrimsonPro",
                                        textAlign: "center",
                                        padding: "5px 5px",
                                        cursor: "pointer",
                                    }}
                                    key={item.value}
                                >
                                    {item.name}
                                </Box>
                            );
                        })}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );
};

export default Language;
