import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import React from "react";
import MenuIcon from "@/assets/menu.png";
import LanguageIcon from "@/assets/language.png";
import MenuModal from "./MenuModal";
import LanguageModal from "./LanguageModal";

const Header = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isLOpen,
        onOpen: onLOpen,
        onClose: onLClose,
    } = useDisclosure();

    return (
        <Flex
            sx={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                zIndex: 1000,
                padding: "28px",
            }}
            alignItems="center"
            justify={"space-between"}
        >
            <Image
                onClick={onOpen}
                src={MenuIcon}
                sx={{
                    width: "20px",
                }}
            ></Image>
            <Image
                src={LanguageIcon}
                sx={{ width: "28px" }}
                onClick={onLOpen}
            ></Image>
            <MenuModal isOpen={isOpen} onClose={onClose}></MenuModal>
            <LanguageModal isOpen={isLOpen} onClose={onLClose}></LanguageModal>
        </Flex>
    );
};
export default Header;
