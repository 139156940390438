import { Box, Text, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useRef } from "react";
import kong2Mp4 from "@/components/Home/assets/kong2.mp4";
import O1Img from "@/components/Home/assets/o1.png";
import { motion, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

const Overview = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const ref = useRef(null);
    const isInView = useInView(ref);
    const { t, i18n } = useTranslation();

    return (
        <Box ref={ref}>
            <video
                autoPlay
                loop
                muted
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    objectFit: "cover",
                }}
            >
                <source src={kong2Mp4} type="video/mp4" />
            </video>

            <motion.div
                animate={{
                    y: isInView ? -140 : 0,
                    opacity: isInView ? 1 : 0.5,
                }}
                transition={{
                    duration: 0.5,
                }}
                style={{
                    position: "absolute",
                    right: "20%",
                    bottom: "0",
                    width: "600px",
                    textAlign: "left",
                    zIndex: 1000,
                    // transform: "translateX(1000px)",
                }}
            >
                <Text
                    sx={{
                        fontSize: "2.3438vw",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    {t("OVERVIEW")}
                </Text>
                <Text
                    sx={{
                        fontSize: "1.0417vw",
                        marginTop: "58px",
                    }}
                >
                    {t("o1des1")}
                    <span
                        style={{
                            color: "#FF3D00",
                        }}
                    >
                        {t("o1des2")}
                    </span>
                </Text>
            </motion.div>
            <Image
                src={O1Img}
                sx={{
                    position: "absolute",
                    left: "0%",
                    bottom: "0%",
                    width: "60%",
                }}
            ></Image>
        </Box>
    );
};

export default Overview;
