import React, { useRef } from "react";
import { Box, Flex, Text, Image, useMediaQuery } from "@chakra-ui/react";
import kong11Mp4 from "@/components/Home/assets/kong11.mp4";
import WUKONG from "@/components/Home/assets/WUKONG.png";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

const MHomeComp = () => {
    const ref = useRef(null);
    const isInView = useInView(ref);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");

    return (
        <Box
            ref={ref}
            sx={{
                position: "relative",
            }}
        >
            <video
                autoPlay
                loop
                muted
                style={{
                    width: "100%",
                    left: 0,
                    top: 0,
                    objectFit: "cover",
                }}
            >
                <source src={kong11Mp4} type="video/mp4" />
            </video>

            <Box
                style={{
                    position: "absolute",
                    left: "50%",
                    bottom: "-30px",
                    transform: "translateX(-50%)",
                }}
            >
                <Image
                    src={WUKONG}
                    sx={{
                        width: "150px",
                    }}
                ></Image>
            </Box>
        </Box>
    );
};

export default MHomeComp;
