import { css } from "@emotion/react";

export const GlobalStyles = css`
    @font-face {
        font-family: "z";
        src: url("/z.ttf") format("opentype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "CrimsonPro";
        src: url("/CrimsonPro.ttf") format("opentype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "HanYiKaiTiFan";
        src: url("/HanYiKaiTiFan.ttf");
    }
    @font-face {
        font-family: "HYSunShangXiangF";
        src: url("/HYSunShangXiangF.ttf");
    }
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }
    html {
        height: 100%;
    }
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: white;
        background: black;
        height: 100%;
    }
    #root {
        height: 100%;
    }
    p {
        margin: 0;
    }
`;
