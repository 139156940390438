import { Box, Text, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import NImg1 from "./assets/n2-img1.png";
import NImg2 from "./assets/n2-img2.png";
import NImg3 from "./assets/n2-img3.png";
import NImg4 from "./assets/n2-img4.png";
import { useTranslation } from "react-i18next";

const News2 = () => {
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");

    return (
        <Box
            sx={{
                lineHeight: "normal",
                fontSize: isPc ? "1.0417vw" : "12px",
            }}
        >
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                }}
            >
                {t("new2des1")}
            </Text>
            <Image
                src={NImg1}
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                }}
            ></Image>
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new2des2")}
            </Text>
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new2des3")}
            </Text>
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new2des4")}
            </Text>
            <Image
                src={NImg2}
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                }}
            ></Image>
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new2des5")}
            </Text>
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new2des6")}
            </Text>
            <Image
                src={NImg3}
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                }}
            ></Image>{" "}
            <Image
                src={NImg4}
                sx={{
                    marginTop: isPc ? "40px" : "20px",

                    width: "550px",
                    margin: "65px auto 0",
                }}
            ></Image>
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                    lineHeight: "normal",
                }}
            >
                {t("new2des7")}
            </Text>
        </Box>
    );
};

export default News2;
