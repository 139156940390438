import {
    Modal,
    Text,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Flex,
    Image,
    Box,
    useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import BtBg from "@/assets/pbg.png";
import YesIcon from "@/assets/yes.png";
import { useTranslation } from "react-i18next";
const PrivacyModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const [isSelect, setIsSelect] = useState(false);
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            autoFocus={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay
                sx={{
                    background: "rgba(217, 217, 217, 0.45)",
                    backdropFilter: "blur(10px)",
                }}
            />
            <ModalContent
                sx={{
                    maxWidth: "720px",
                    background: `url(${BtBg})`,
                    padding: isPc ? "20px" : "20px 0px 20px",
                    margin: "0 20px",
                }}
            >
                <ModalBody
                    sx={{
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                        maxHeight: "60vh",
                        overflowY: "auto",
                    }}
                >
                    <Text
                        sx={{
                            fontStyle: "normal",
                            lineHeight: "normal",
                            fontSize: isPc ? "25px" : "15px",
                            fontWeight: 600,
                            textAlign: "center",
                        }}
                    >
                        {t("userPrivacy")}{" "}
                    </Text>
                    <Box
                        sx={{
                            color: "#FFF",
                            textAlign: "justify",
                            fontFamily:
                                i18n.language === "tw"
                                    ? "HanYiKaiTiFan"
                                    : "CrimsonPro",
                            fontSize: isPc ? "15px" : "12px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            "&>p": {
                                marginTop: isPc ? "20px" : "10px",
                            },
                        }}
                    >
                        <Text>{t("userPdes1")}</Text>
                        <Text>{t("userPdes2")}</Text>
                        <Text>{t("userPdes3")}</Text>
                        <Text>{t("userPdes4")}</Text>
                        <Text>{t("userPdes5")}</Text>
                        <Text>{t("userPdes6")}</Text>
                        <Text>{t("userPdes7")}</Text>
                    </Box>
                    <Flex
                        sx={{
                            marginTop: isPc ? "20px" : "10px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setIsSelect(!isSelect);
                        }}
                        align={"center"}
                    >
                        <Flex
                            sx={{
                                width: "13px",
                                height: "13px",
                                border: isSelect
                                    ? "1px solid #fff"
                                    : "1px solid #7A7A7A",
                                marginRight: "7px",
                            }}
                        >
                            {isSelect && <Image src={YesIcon}></Image>}
                        </Flex>
                        <Text
                            sx={{
                                fontSize: isPc ? "15px" : "12px",
                                fontWeight: 600,
                                color: isSelect ? "#fff" : "#7A7A7A",
                            }}
                        >
                            {t("userPdes8")}
                        </Text>
                    </Flex>
                    <Flex
                        onClick={() => {
                            if (isSelect) {
                                localStorage.setItem("privacy", "true");
                                onClose();
                            }
                        }}
                        sx={{
                            width: "144px",
                            height: "36px",
                            padding: "9px 40px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            flexShrink: 0,
                            borderRadius: "5px",
                            margin: "20px auto 0",
                            fontSize: "15px",
                            color: isSelect ? "#fff" : "#747474",
                            cursor: isSelect ? "pointer" : "not-allowed",
                            background: isSelect
                                ? "var(--Linear, linear-gradient(180deg, #FFC700 0%, #AD6800 100%))"
                                : "#3a3a3a",
                        }}
                    >
                        {t("userPdes9")}
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
export default PrivacyModal;
