import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useRef } from "react";
import kong2Mp4 from "@/components/Home/assets/kong22.mp4";
import O1Img from "@/components/Home/assets/o1.png";
import { useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

const MOverview = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const ref = useRef(null);
    const isInView = useInView(ref);
    const { t, i18n } = useTranslation();

    return (
        <Box
            ref={ref}
            sx={{
                // height: "100vh",
                position: "relative",
                marginTop: "40px",
            }}
        >
            <Box
                sx={{
                    // position: "absolute",
                    // left: "50%",
                    // top: "44%",
                    width: "90%",
                    textAlign: "left",
                    zIndex: 1000,
                    // transform: "translateX(-50%)",
                    opacity: isInView ? 1 : 0,
                    transition: "opacity 3s",
                    margin: "0 auto",
                    background: `url(${O1Img}) no-repeat `,
                    // backgroundSize: "contain",
                    backgroundPosition: "0% 0%",
                    backgroundSize: "100%",
                    paddingTop: "100px",
                }}
            >
                <Text
                    sx={{
                        fontSize: "15px",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    {t("OVERVIEW")}
                </Text>
                <Text
                    sx={{
                        fontSize: "12px",
                        marginTop: "8px",
                    }}
                >
                    {t("o1des1")}
                    <span
                        style={{
                            color: "#FF3D00",
                        }}
                    >
                        {t("o1des2")}
                    </span>
                </Text>
                <video
                    autoPlay
                    loop
                    muted
                    style={{
                        width: "100%",
                        objectFit: "cover",
                        // position: "absolute",
                        top: "70%",
                        left: "50%",
                        // transform: "translate(-50%, -50%)",
                    }}
                >
                    <source src={kong2Mp4} type="video/mp4" />
                </video>
            </Box>
        </Box>
    );
};

export default MOverview;
