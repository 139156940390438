import { Box, Text, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import NImg1 from "./assets/n5-img1.png";
import NImg2 from "./assets/n5-img2.png";
import NImg3 from "./assets/n5-img3.png";
import NImg4 from "./assets/n5-img4.png";
import { useTranslation } from "react-i18next";

const News5 = () => {
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");

    return (
        <Box
            sx={{
                lineHeight: "normal",
                fontSize: isPc ? "1.0417vw" : "12px",
            }}
        >
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                }}
            >
                {t("new5des1")}
            </Text>
            <Image
                src={NImg1}
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                }}
            ></Image>
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new5des2")}
            </Text>
            <Image
                src={NImg2}
                sx={{
                    marginTop: "30px",
                }}
            ></Image>{" "}
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new5des3")}
            </Text>{" "}
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new5des4")}
            </Text>
            <Image
                src={NImg3}
                sx={{
                    marginTop: "30px",
                }}
            ></Image>{" "}
            <Text
                sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    marginTop: "20px",
                }}
            >
                {t("new5des5")}
            </Text>{" "}
            <Image
                src={NImg4}
                sx={{
                    marginTop: "0px",
                }}
            ></Image>{" "}
        </Box>
    );
};

export default News5;
