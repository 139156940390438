import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export const menus = [
    {
        name: "HOME",
    },
    {
        name: "EXCHANGE",
    },
    { name: "OVERVIEW" },
    {
        name: "PROGRAM",
    },
    {
        name: "TOKENOMICS",
    },
];
const NavBar = ({
    activeIndex,
    handleActiveIndex,
}: {
    activeIndex: number;
    handleActiveIndex: (index: number) => void;
}): ReactElement => {
    const { t, i18n } = useTranslation();
    const [isPc] = useMediaQuery("(min-width: 800px)");

    return (
        <Box
            sx={{
                position: "fixed",
                top: "92px",
                left: "80px",
                height: "50px",
                zIndex: 1000,
                fontFamily:
                    i18n.language === "tw" ? "HanYiKaiTiFan" : "CrimsonPro",
            }}
        >
            {menus.map((item, index) => {
                return (
                    <Flex
                        align={"center"}
                        key={index}
                        sx={{
                            cursor: "pointer",
                            marginBottom: "30px",
                        }}
                        onClick={() => handleActiveIndex(index)}
                    >
                        <Box
                            sx={{
                                width: "10px",
                                height: "10px",
                                transform: "rotate(45deg)",
                                backgroundColor: "#fff",
                                marginRight: "12px",
                            }}
                        ></Box>
                        <Text
                            sx={{
                                fontSize:
                                    activeIndex === index
                                        ? "1.8229vw"
                                        : "1.0417vw",
                            }}
                        >
                            {t(item.name)}
                        </Text>
                    </Flex>
                );
            })}
        </Box>
    );
};

export default NavBar;
