import {
    Box,
    Flex,
    Input,
    Text,
    useDisclosure,
    useMediaQuery,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import LeftBBg from "@/components/Home/assets/left-b.png";
import RightBBg from "@/components/Home/assets/right-b.png";
import BtBg from "@/components/Home/assets/exchange-bt.png";
import SuccessModal from "./SuccessModal";
import FailModal from "./FailModal";
const Exchange = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: failIsOpen,
        onOpen: failOnOpen,
        onClose: failOnClose,
    } = useDisclosure();

    const { t, i18n } = useTranslation();
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);

    const inputs = [
        inputRef1,
        inputRef2,
        inputRef3,
        inputRef4,
        inputRef5,
        inputRef6,
    ];

    const handleInput = (index: number, value: string) => {
        if (inputs[index].current) {
            if (inputs[index].current.value.length >= 1) {
                inputs[index].current.value = value.slice(0, 1);
                if (index < 5) {
                    inputs[index + 1].current.focus();
                }
            }
        }
    };

    const handleBackspace = () => {
        for (let i = 5; i >= 0; i--) {
            if (inputs[i].current) {
                if (inputs[i].current.value.length > 0) {
                    inputs[i].current.value = "";
                    if (i > 0) {
                        inputs[i - 1].current.focus();
                    }
                    break;
                } else if (inputs[i].current.value.length === 0) {
                    // inputs[i].current.value = "";
                    if (i > 0) {
                        inputs[i - 1].current.focus();
                    }
                }
            }
        }
    };

    return (
        <Flex
            sx={{
                height: "100%",
            }}
            align={"center"}
            justify={"center"}
        >
            <Box
                sx={{
                    width: "550px",
                    height: "180px",
                    position: "relative",
                    borderRadius: "10px",
                    background: "rgba(255, 255, 255, 0.20)",
                    backdropFilter: "blur(10px)",
                    padding: "30px 25px",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        background: `url(${LeftBBg}), url(${RightBBg})`,
                        backgroundSize: "contain, contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "left, right",
                        position: "relative",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    <Text
                        sx={{
                            position: "absolute",
                            top: "-18px",
                            left: "50%",
                            transform: "translate(-50%, 0%)",
                            fontSize: "25px",
                            width: "100%",
                        }}
                    >
                        {t("e1des")}
                    </Text>
                    <Flex
                        sx={{
                            background: `url(${BtBg})`,
                            width: "156px",
                            height: "45px",
                            cursor: "pointer",
                            backgroundSize: "100% 100%",
                            position: "absolute",
                            left: "48%",
                            bottom: "-22px",
                            transform: "translate(-50%, 0%)",
                            fontSize: "20px",
                        }}
                        justify={"center"}
                        align={"center"}
                        onClick={onOpen}
                    >
                        {t("enter")}
                    </Flex>
                    <Flex
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            gap: "10px",
                        }}
                    >
                        {[1, 2, 3, 4, 5, 6].map((item, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        width: "60px",
                                        height: "30px",
                                        borderRadius: "5px",
                                        background: "#FFF",
                                    }}
                                >
                                    <Input
                                        ref={inputs[index]}
                                        sx={{
                                            minHeight: "0",
                                            border: "none",
                                            padding: "0",
                                            height: "100%",
                                            color: "#000",
                                            textAlign: "center",
                                            fontSize: "16px",
                                        }}
                                        onChange={(e) => {
                                            handleInput(index, e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            console.log(e.key);
                                            if (e.key === "Backspace") {
                                                e.preventDefault();
                                                handleBackspace();
                                            }
                                        }}
                                        onFocus={() => {}}
                                    ></Input>
                                </Box>
                            );
                        })}
                    </Flex>
                </Box>
            </Box>
            <SuccessModal isOpen={isOpen} onClose={onClose}></SuccessModal>
            <FailModal isOpen={failIsOpen} onClose={failOnClose}></FailModal>
        </Flex>
    );
};

export default Exchange;
