import { Box, Text, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import NImg1 from "./assets/n-img1.png";
import NImg2 from "./assets/n-img2.png";
import NImg3 from "./assets/n-img3.png";
import { useTranslation } from "react-i18next";

const News1 = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");
    const { t, i18n } = useTranslation();

    return (
        <Box sx={{}}>
            <Text
                sx={{
                    fontSize: isPc ? "1.0417vw" : "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                }}
            >
                {t("new1des1")}
            </Text>
            <Image
                src={NImg1}
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                }}
            ></Image>
            <Text
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                    fontWeight: 700,
                }}
            >
                {t("new1des2")}
            </Text>
            <Text
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                    fontWeight: 700,
                }}
            >
                {t("new1des3")}
            </Text>
            <Image
                src={NImg2}
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                }}
            ></Image>
            <Text
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                    fontWeight: 700,
                }}
            >
                {t("new1des4")}
            </Text>{" "}
            <Image
                src={NImg3}
                sx={{
                    marginTop: isPc ? "40px" : "20px",
                }}
            ></Image>
        </Box>
    );
};

export default News1;
