import React, { useRef } from "react";
import {
    Box,
    Flex,
    Text,
    Image,
    useClipboard,
    useMediaQuery,
} from "@chakra-ui/react";
import { motion, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
import CopyIcon from "@/components/Home/assets/copy1.png";

const Tokenpmic = () => {
    const [isPc] = useMediaQuery("(min-width: 800px)");

    const ref = useRef(null);
    const isInView = useInView(ref);
    const { t, i18n } = useTranslation();

    const { onCopy } = useClipboard("0xxxxxxXXXXXXXXXXXXXXXXXXXXXXXXX");

    return (
        <Box ref={ref}>
            <motion.div
                animate={{
                    y: isInView ? 140 : 0,
                    opacity: isInView ? 1 : 0.5,
                }}
                transition={{
                    duration: 0.5,
                }}
                style={{
                    position: "absolute",
                    top: "0",
                    right: "180px",
                    width: "370px",
                    textAlign: "left",
                }}
            >
                <Text
                    sx={{
                        fontSize: "2.3438vw",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    {t("TOKENPMIC")}
                </Text>
                <Box
                    sx={{
                        lineHeight: "2.0833vw",
                        fontSize: "1.8229vw",
                        fontFamily:
                            i18n.language === "tw"
                                ? "HanYiKaiTiFan"
                                : "CrimsonPro",
                    }}
                >
                    <Text
                        sx={{
                            marginTop: "76px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            50%{" "}
                        </span>{" "}
                        {t("t1des1")}
                    </Text>
                    <Text
                        sx={{
                            marginTop: "18px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            20%{" "}
                        </span>{" "}
                        {t("t1des3")}
                    </Text>
                    <Text
                        sx={{
                            marginTop: "18px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            20%{" "}
                        </span>{" "}
                        LP{" "}
                    </Text>

                    <Text
                        sx={{
                            marginTop: "18px",
                        }}
                    >
                        <span
                            style={{
                                color: "#FF3D00",
                            }}
                        >
                            10%{" "}
                        </span>{" "}
                        {t("t1des2")}
                    </Text>
                </Box>
            </motion.div>
            <Flex
                sx={{
                    fontFamily:
                        i18n.language === "tw" ? "HanYiKaiTiFan" : "CrimsonPro",
                    fontSize: "1.8229vw",
                    fontWeight: 900,
                    position: "absolute",
                    left: "176px",
                    bottom: "160px",
                    cursor: "pointer",
                }}
                align={"center"}
                onClick={onCopy}
            >
                <Text sx={{ marginRight: "20px" }}>
                    {" "}
                    CA：0xxxxxxXXXXXXXXXXXXXXXXXXXXXXXXX
                </Text>
                <Image
                    src={CopyIcon}
                    sx={{
                        width: "1.0417vw",
                        height: "1.0417vw",
                    }}
                ></Image>
            </Flex>
        </Box>
    );
};

export default Tokenpmic;
